import React, { useState, useEffect, useMemo, useContext } from "react";
import { Typography, Grid, Card, CardHeader, CardContent } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import MaterialReactTable from 'material-react-table';
import { useNavigate } from "react-router-dom";
import { DraftYearContext } from "../../contexts/DraftYearContext";
import { playerColumns } from "./Constants.js";
import PageLoader from "../../components/PageLoader/PageLoader";



import axios from "axios";
import PlayerCard from "../../components/PlayerCard/PlayerCard";
import { Container } from "@mui/system";
import { set } from "date-fns";

function Players() {
    const [players, setPlayers] = useState(null);
    const [topPlayers, setTopsPlayers] = useState(null);
    const { draftYear, setDraftYear } = useContext(DraftYearContext);
    const navigate = useNavigate();

    const [error, setError] = useState(null);

    // Loading States
    const [loading, setLoading] = useState(true);
    const [draftYearChange, setDraftYearChange] = useState(true);
    const [prevDraftYear, setPrevDraftYear] = useState(null);

    useEffect(() => {
        setLoading(true);
        axios.get(process.env.REACT_APP_API_URL + "/players", { params: { "draft_year": draftYear } }).then((response) => {
            setPlayers(response.data);
            setTopsPlayers(response.data.slice(0, 6));
            setLoading(false);
        })
            .catch((error) => {
                setError(error);
            });
    }, [draftYear]);

    const columns = useMemo(
        () => playerColumns,
        [],
    );

    useEffect(() => {
        if (prevDraftYear == null) {
            setPrevDraftYear(draftYear);
        }
        else {
            if (prevDraftYear != draftYear) {
                setDraftYearChange(false);
            }
        }
        setPrevDraftYear(draftYear);
    }, [draftYear]);

    function getIsLoading() {
        return loading && !draftYearChange;
    }

    let darkTheme = createTheme({
        palette: {
            mode: 'dark', // Switching the dark mode on is a single property value change.

            info: {
                main: '#678651', //add in a custom color for the toolbar alert background stuff
                secondary: '#678651',
            },
            background: {
                default: 'rgb(37, 37, 38, 0.2)', //pure black table in dark mode for fun
            },
        },
        shadows: ["none"]
    });

    useEffect(() => {
        document.title = 'Draft Prospects - Hockey Pipeline';
    }, []);

    return (
        <>
            <PageLoader loading={getIsLoading()} />
            <Container className="container top-container" >
                <Grid container columnSpacing={4} spacing={2}>
                    <Grid item xs={12} sm={12} md={12} >
                        <Typography variant="h4" component="div" className="page-title">
                            Top 6 Prospects
                        </Typography>
                        <div className="title-underline" />
                    </Grid>
                    {topPlayers && topPlayers.map((player) => (
                        <Grid item xs={6} sm={6} md={2}>
                            <PlayerCard playerData={player} shortView={true} />
                        </Grid>
                    ))}
                </Grid>
            </Container>

            <Container spacing={2} style={{ marginTop: 40, borderRadius: "10px" }} className="container" >
                <Grid container columnSpacing={4}>
                    <Grid item xs={12} sm={12} md={12} >
                        <Typography variant="h4" component="div" className="page-title">
                            All Prospects
                        </Typography>
                        <div className="title-underline" />
                    </Grid>
                    {players && <Grid item xs={12} sm={12} md={12}>
                        <ThemeProvider theme={darkTheme}>
                            <MaterialReactTable
                                columns={columns}
                                data={players}
                                initialState={{ density: 'compact', pagination: { pageSize: 32 } }}
                                muiTableBodyRowProps={({ row }) => ({
                                    onClick: (event) => {
                                        navigate('/player/' + row.original.player_id);
                                    },
                                    sx: {
                                        cursor: 'pointer',
                                    },
                                })}

                            />;
                        </ThemeProvider>
                    </Grid>}
                </Grid>
            </Container>
        </>
    );
};

export default Players;
