import React from "react";
import { Typography, Card, CardContent, Chip, Grid } from '@mui/material';
import { format } from "date-fns";
import { Link } from 'react-router-dom';

import "./PlayerRankMockCard.css"

function PlayerRankMockCard({ playerRankMock }) {
    var date = new Date(playerRankMock.creation_date);
    var formattedDate = format(date, "MMM do, yyyy");
    return (
        <Card className="player-rank-mock-card">
            <CardContent className="player-rank-mock-card-content">
                <Grid container columnSpacing={0}>
                    <Grid item xs={2} s={1} md={1}>
                        <div className="player-rank-mock-card-rank-box">
                            <Typography variant="h5" component="div" className="player-rank-mock-header">
                                <Typography variant="body2" component="h2" className="player-rank-title" >
                                    {(playerRankMock.rank_type == "ranking") ? "RANK:" : "PICK:"}
                                </Typography>
                                <Typography variant="h1" component="h2" style={{ fontWeight: 1000 }} className="player-rank-value">
                                    {playerRankMock.rank}
                                </Typography>
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={10} s={11} md={11} >
                        <div className="player-rank-mock-card-description">
                            <Typography variant="h5" component="div" className="player-rank-mock-header">
                                <Link to={((playerRankMock.rank_type === "mock_draft") ? "../mock-draft/" : "../ranking/") + playerRankMock.data_point_id}
                                    style={{ color: "#1976d2", fontSize: "24px" }}>
                                    {playerRankMock.title}
                                </Link>
                            </Typography>
                            <div className="player-rank-mock-subheader">
                                <Chip label={(playerRankMock.rank_type == "ranking") ? "Ranking" : "Mock Draft"} size="small" color={(playerRankMock.rank_type == "ranking") ? "primary" : "success"} variant="outlined" />
                                <div className="sub-heading-divider"></div>
                                <Typography color="textSecondary" sx={{ mb: 1 }}>
                                    {formattedDate}
                                </Typography>
                                <div className="sub-heading-divider"></div>
                                <Typography color="textSecondary" sx={{ mb: 1 }}>
                                    <Link to={"../mocks-and-rankings?scouts_id=" + playerRankMock.scouts_id} style={{ color: "#1976d2" }}> {playerRankMock.scout_name}</Link>
                                </Typography>
                                <div className="sub-heading-divider"></div>
                                <Typography color="textSecondary" sx={{ mb: 1 }}>
                                    <Link to={"../mocks-and-rankings?publication_id=" + playerRankMock.publication_id} style={{ color: "#1976d2" }}> {playerRankMock.publication_name}</Link>
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </CardContent>
            <div className="player-rank-mock-card-divider"></div>
        </Card>

    );
};

export default PlayerRankMockCard;