import React, { useState, useEffect, useMemo } from "react";
import { Typography, Grid } from '@mui/material';
import { useParams, useSearchParams } from "react-router-dom";
import moment from 'moment';

import axios from "axios";
import Button from '@mui/material/Button';
import PlayerCard from "../../components/PlayerCard/PlayerCard";
import { Container } from "@mui/system";
import DataComparisonRow from "./DataComparisonRow";
import PlayerComparisonGraph from "../../components/PlayerComparisonGraph/PlayerComparisonGraph";
import TextField from '@mui/material/TextField';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import Popper from '@mui/material/Popper';
import { useTheme, styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import ClearIcon from '@mui/icons-material/Clear';

import "./PlayerComparison.css"
import { set } from "date-fns";

function PlayerComparison() {

    const [searchParams, setSearchParams] = useSearchParams();
    const [playerOneId, setPlayerOneId] = useState(searchParams.get('player_one_id'));
    const [playerTwoId, setPlayerTwoId] = useState(searchParams.get('player_two_id'));

    const [playerOneData, setPlayerOneData] = useState(null);
    const [playerOneStats, setPlayerOneStats] = useState(null);
    const [playerTwoData, setPlayerTwoData] = useState(null);
    const [playerTwoStats, setPlayerTwoStats] = useState(null);

    const [playerOneGraphData, setPlayerOneGraphData] = useState(null);
    const [playerTwoGraphData, setPlayerTwoGraphData] = useState(null);

    const [playerOneLastConsensusData, setPlayerOneLastConsensusData] = useState(null);
    const [playerTwoLastConsensusData, setPlayerTwoLastConsensusData] = useState(null);

    const [error, setError] = useState(null);

    const [allPlayerOneOptions, setAllPlayerOneOptions] = useState([]);
    const [playerOneOptions, setPlayerOneOptions] = useState([]);
    const [playerOneInputValue, setPlayerOneInputValue] = useState('');

    const [allPlayerTwoOptions, setAllPlayerTwoOptions] = useState([]);
    const [playerTwoOptions, setPlayerTwoOptions] = useState([]);
    const [playerTwoInputValue, setPlayerTwoInputValue] = useState('');
    const navigate = useNavigate();

    const StyledPopper = styled(Popper)({
        [`& .${autocompleteClasses.listbox}`]: {
            boxSizing: 'border-box',
            backgroundColor: '#1e1e1e',
            color: 'white',
            border: 'solid 1px white',
            padding: '0px',

            '& li:hover': {
                backgroundColor: '#424242',
            },
            '& li': {
                borderBottom: 'solid 1px white',
            }
        },
    });

    useEffect(() => {
        if (playerTwoData && playerOneData) {
            document.title = playerOneData.first_name + " " + playerOneData.last_name + " vs. " + playerTwoData.first_name + " " + playerTwoData.last_name + " - Hockey Pipeline" 
        }
        else if (playerTwoData){
            document.title = playerTwoData.first_name + " " + playerTwoData.last_name + " vs. ??? - Hockey Pipeline" 

        }

        else if (playerOneData){
            document.title = playerOneData.first_name + " " + playerOneData.last_name + " vs. ??? - Hockey Pipeline" 
        }

        else {   
            document.title = "Player Comparison - Hockey Pipeline"
        }
    }, [playerOneData, playerTwoData]);


    // ONLY QUERY API IF INPUT VALUE IS 3 CHARACTERS, OTHERWISE FILTER OPTIONS LOCALLY
    const handlePlayerOneInputChange = (event, value) => {
        setPlayerOneInputValue(event.target.value);
        if (event.target.value.length == 3) {
            // Call API to populate dropdown options
            fetchPlayerOneOptions(event.target.value);
        } else if (event.target.value.length > 3) {
            const constructedPlayerId = event.target.value.replace(/\s/g, '_').toLowerCase();
            setPlayerOneOptions(allPlayerOneOptions.filter((option) => option.player_id.includes(constructedPlayerId)));
        }
        else {
            setPlayerOneOptions([]); // Clear options if less than 3 characters
        }
    };

    const fetchPlayerOneOptions = async (value) => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + "/players/search/" + value);
            setAllPlayerOneOptions(response.data);
            setPlayerOneOptions(response.data);
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };


    // ONLY QUERY API IF INPUT VALUE IS 3 CHARACTERS, OTHERWISE FILTER OPTIONS LOCALLY
    const handlePlayerTwoInputChange = (event, value) => {
        setPlayerTwoInputValue(event.target.value);
        if (event.target.value.length == 3) {
            // Call API to populate dropdown options
            fetchPlayerTwoOptions(event.target.value);
        } else if (event.target.value.length > 3) {
            const constructedPlayerId = event.target.value.replace(/\s/g, '_').toLowerCase();
            setPlayerTwoOptions(allPlayerTwoOptions.filter((option) => option.player_id.includes(constructedPlayerId)));
        }
        else {
            setPlayerTwoOptions([]); // Clear options if less than 3 characters
        }
    };

    const fetchPlayerTwoOptions = async (value) => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + "/players/search/" + value);
            setAllPlayerTwoOptions(response.data);
            setPlayerTwoOptions(response.data);
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };



    useEffect(() => {
        if (playerOneId == null) {return;}
        axios.get(process.env.REACT_APP_API_URL + "/players/" + playerOneId).then((response) => {
            setPlayerOneData(response.data);
        })
            .catch((error) => {
                setError(error);
            });
    }, [playerOneId]);


    useEffect(() => {
        if (playerOneId == null) {return;}
        axios.get(process.env.REACT_APP_API_URL + "/player_stats_per_game/" + playerOneId).then((response) => {
            setPlayerOneStats(response.data);
        })
            .catch((error) => {
                setError(error);
            });
    }, [playerOneId]);

    useEffect(() => {
        if (playerOneId == null) {return;}
        axios.get(process.env.REACT_APP_API_URL + "/consensus_rankings/" + playerOneId).then((response) => {
            setPlayerOneLastConsensusData(response.data[response.data.length - 1]);
            return response.data
        }).then((re) => {
            const scores = re.map(item => Math.round(item.score * 1e2) / 1e2);
            const effectiveDates = re.map(item => moment(item.effective_date).valueOf());
            const lastRank = re.map(item => ((item.has_data_point) ? item.last_rank : null));
            const consensusRanking = re.map(item => item.rank);
            const lastRankType = re.map(item => item.last_rank_type);

            // Create an array of objects combining the effective date, score, and rank
            setPlayerOneGraphData(re.map((item, index) => ({
                effective_date: effectiveDates[index],
                score: scores[index],
                lastRank: lastRank[index],
                consensusRanking: consensusRanking[index],
                lastRankType: lastRankType[index]
            })));
        })
            .catch((error) => {
                setError(error);
            });
    }, [playerOneId]);


    useEffect(() => {
        if (playerTwoId == null) {return;}
        axios.get(process.env.REACT_APP_API_URL + "/players/" + playerTwoId).then((response) => {
            setPlayerTwoData(response.data);
        })
            .catch((error) => {
                setError(error);
            });
    }, [playerTwoId]);


    useEffect(() => {
        if (playerTwoId == null) {return;}
        axios.get(process.env.REACT_APP_API_URL + "/player_stats_per_game/" + playerTwoId).then((response) => {
            setPlayerTwoStats(response.data);
        })
            .catch((error) => {
                setError(error);
            });
    }, [playerTwoId]);

    useEffect(() => {
        if (playerTwoId == null) {return;}
        axios.get(process.env.REACT_APP_API_URL + "/consensus_rankings/" + playerTwoId).then((response) => {
            setPlayerTwoLastConsensusData(response.data[response.data.length - 1]);
            return response.data
        }).then((re) => {
            const scores = re.map(item => Math.round(item.score * 1e2) / 1e2);
            const effectiveDates = re.map(item => moment(item.effective_date).valueOf());
            const lastRank = re.map(item => ((item.has_data_point) ? item.last_rank : null));
            const consensusRanking = re.map(item => item.rank);
            const lastRankType = re.map(item => item.last_rank_type);

            // Create an array of objects combining the effective date, score, and rank
            setPlayerTwoGraphData(re.map((item, index) => ({
                effective_date: effectiveDates[index],
                score: scores[index],
                lastRank: lastRank[index],
                consensusRanking: consensusRanking[index],
                lastRankType: lastRankType[index]
            })));
        })
            .catch((error) => {
                setError(error);
            });
    }, [playerTwoId]);

    function betterPlayer(player, comp) {
        if (player && comp) {
            return player.consensus_ranking < comp.consensus_ranking;
        }
        return false;
    }

    return (
        <div className="comparison-page">

            {/* PLAYER CARD AND GRAPH */}
            <Container style={{ marginTop: 40 }}>
                <Grid container columnSpacing={{ xs: 1, sm: 2, md: 2 }} >
                    <Grid item xs={6} sm={6} md={4} >
                        <div className="comparison-search-row">
                            <Autocomplete
                                value={playerOneData}
                                disableClearable={true}
                                className="autocomplete-search-bar comparison-search-bar"
                                PopperComponent={StyledPopper}
                                options={playerOneOptions}
                                getOptionLabel={(option) => (option.first_name + " " + option.last_name)}
                                onChange={(event, option, reason) => {
                                    setPlayerOneOptions([]);
                                    if (option && reason !== 'clear') {
                                        setSearchParams({ player_one_id: option.player_id, player_two_id: searchParams.get('player_two_id') });
                                        setPlayerOneId(option.player_id);
                                    }
                                }
                                }
                                renderInput={(params) => (
                                    <TextField
                                        className="search-bar"
                                        {...params}
                                        label="Player Search"
                                        value={playerOneInputValue}
                                        onChange={handlePlayerOneInputChange}
                                    />
                                )}
                            />

                            <Button variant="contained" className="clear-comparison" onClick={() => {
                                setPlayerOneId(null);
                                setSearchParams({ player_two_id: searchParams.get('player_two_id') });
                                setPlayerOneData(null);
                                setPlayerOneStats(null);
                                setPlayerOneLastConsensusData(null);
                                setPlayerOneGraphData(null);
                                setPlayerOneInputValue(null);
                            }}><ClearIcon fontSize="inherit"/></Button>
                        </div>
                        {playerOneData && <PlayerCard playerData={playerOneData} glow={betterPlayer(playerOneData, playerTwoData)} />}
                    </Grid>
                    {<Grid item xs={12} sm={12} md={4} order={{ xs: 3, sm: 3, md: 2 }}>
                        <div className="container stat-comparison-container">
                            <Typography variant="h4" component="div" className="comparison-title">
                                Stats Comparison
                            </Typography>

                            <DataComparisonRow p1={playerOneStats} p2={playerTwoStats} dataTitle={"Games Played"} dataKey={"games_played"} greaterBetter={true} />

                            <DataComparisonRow p1={playerOneStats} p2={playerTwoStats} dataTitle={"Points Per Game"} dataKey={"points_per_game"} greaterBetter={true} includeDecimal={true} />

                            <DataComparisonRow p1={playerOneStats} p2={playerTwoStats} dataTitle={"Goals Per Game"} dataKey={"goals_per_game"} greaterBetter={true} includeDecimal={true} />

                            <DataComparisonRow p1={playerOneStats} p2={playerTwoStats} dataTitle={"Assists Per Game"} dataKey={"assists_per_game"} greaterBetter={true} includeDecimal={true} />

                            <DataComparisonRow p1={playerOneStats} p2={playerTwoStats} dataTitle={"PIMs Per Game"} dataKey={"penalty_minutes_per_game"} greaterBetter={false} includeDecimal={true} />

                            <DataComparisonRow p1={playerOneData} p2={playerTwoData} dataTitle={"Weight (lbs)"} dataKey={"weight"} greaterBetter={true} />

                            <DataComparisonRow p1={playerOneData} p2={playerTwoData} dataTitle={"Height"} dataKey={"height"} greaterBetter={true} />

                            <DataComparisonRow p1={playerOneLastConsensusData} p2={playerTwoLastConsensusData} dataTitle={"Best Rank"} dataKey={"best_rank"} greaterBetter={false} />

                            <DataComparisonRow p1={playerOneLastConsensusData} p2={playerTwoLastConsensusData} dataTitle={"Worst Rank"} dataKey={"worst_rank"} greaterBetter={false} />

                            <DataComparisonRow p1={playerOneLastConsensusData} p2={playerTwoLastConsensusData} dataTitle={"Ranking Count"} dataKey={"count"} greaterBetter={true} />
                        </div>
                    </Grid>}
                    <Grid item xs={6} sm={6} md={4} order={{ xs: 2, sm: 2, md: 3 }}>
                        <div className="comparison-search-row">
                            <Autocomplete
                                value={playerTwoData}
                                disableClearable={true}
                                className="autocomplete-search-bar comparison-search-bar"
                                PopperComponent={StyledPopper}
                                options={playerTwoOptions}
                                getOptionLabel={(option) => (option.first_name + " " + option.last_name)}
                                onChange={(event, option, reason) => {
                                    setPlayerTwoOptions([]);
                                    if (option && reason !== 'clear') {
                                        setSearchParams({ player_one_id: searchParams.get('player_one_id'), player_two_id: option.player_id });
                                        setPlayerTwoId(option.player_id);
                                    }
                                }
                                }
                                renderInput={(params) => (
                                    <TextField
                                        className="search-bar"
                                        {...params}
                                        label="Player Search"
                                        value={playerTwoInputValue}
                                        onChange={handlePlayerTwoInputChange}
                                    />
                                )}
                            />

                            <Button variant="contained" className="clear-comparison" onClick={() => {
                                setPlayerTwoId(null);
                                setSearchParams({ player_one_id: searchParams.get('player_one_id') });
                                setPlayerTwoData(null);
                                setPlayerTwoStats(null);
                                setPlayerTwoLastConsensusData(null);
                                setPlayerTwoGraphData(null);
                                setPlayerTwoInputValue(null);
                            }}><ClearIcon fontSize="inherit"/></Button>

                        </div>
                        {playerTwoData && <PlayerCard playerData={playerTwoData} glow={betterPlayer(playerTwoData, playerOneData)} />}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12}  >
                    {(playerOneGraphData || playerTwoGraphData) && <div className="container top-container">
                        {(playerOneData || playerTwoData) && <PlayerComparisonGraph graphDataOne={playerOneGraphData} graphDataTwo={playerTwoGraphData}
                            playerOneData={playerOneData} playerTwoData={playerTwoData} />}
                    </div>}
                </Grid>

            </Container>
        </div>
    );
};

export default PlayerComparison;
