import { React, CSSProperties } from 'react';
import "./PageLoader.css"
import MoonLoader from "react-spinners/MoonLoader";

function PageLoader({ loading }) {

    const override: CSSProperties = {
        display: "block",
        position: "fixed",
        margin: "0 auto",
        borderColor: "white",
        zIndex: 1000,
    };

    return (
        <>
        { loading &&
        <div className="page-loader">
            <MoonLoader
                color={"#FFFFFF"}
                loading={loading}
                cssOverride={override}
                size={150}
                aria-label="Loading Spinner"
                data-testid="loader"
                speedMultiplier={0.75}
            />
        </div>
        }
    </>
    );
};

export default PageLoader;