import { Typography } from '@mui/material';
import { format } from "date-fns";


const PlayerComparisonGraphToolTip = ({ active, payload }) => {
    function dateFormatter(tickItem) {
        return format(new Date(tickItem), "d-MMM-yyyy");
    }

    const scout_ranking_name = (payload[0] ? payload[0].name : null)
    const scout_ranking_color = (payload[0] ? payload[0].color.slice(0, 7) : null)
    const scout_ranking_value = (payload[0] ? payload[0].value : null)

    const consensus_ranking_name = (payload[1] ? payload[1].name : null)
    const consensus_ranking_color = (payload[1] ? payload[1].color.slice(0, 7) : null)
    const consensus_ranking_value = (payload[1] ? payload[1].value : null)


    const effective_date_value = (payload[0] ? payload[0].payload.effective_date : null)

    return (
        <div className="tooltip-box">
            <Typography variant="h4" component="div" className='tool-tip-title'>
                <div className='tool-tip-name'>Date </div> <div className='tool-tip-value'>{dateFormatter(effective_date_value)}</div>
            </Typography>
            <Typography variant="h4" component="div" className='tool-tip-title' style={{ color: scout_ranking_color }}>
                <div className='tool-tip-name'>{scout_ranking_name} </div> <div className='tool-tip-value'>{scout_ranking_value}</div>
            </Typography>
            <Typography variant="h4" component="div" className='tool-tip-title' style={{ color: consensus_ranking_color }}>
                <div className='tool-tip-name'>{consensus_ranking_name}</div> <div className='tool-tip-value'>{consensus_ranking_value}</div>
            </Typography>
        </div>
    );
}

export default PlayerComparisonGraphToolTip