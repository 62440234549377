import React, {useEffect} from "react";
import { Container, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';


const TermsOfService = () => {
    useEffect(() => {
        document.title = 'Terms of Service - Hockey Pipeline';
      }, []);
    return (
        <Container className="container top-container" >
            <Grid container columnSpacing={4}>
                <Grid item xs={12} sm={12} md={12} >
                    <Typography variant="h4" component="div" className="page-title">
                        TERMS OF SERVICE
                    </Typography>
                    <div className="title-underline" />
                </Grid>
                <Grid item xs={12} sm={12} md={12} >
                    <Typography variant="body1" paragraph>

                        <p><strong>I. End User Licence Agreement</strong></p>

                        <p><strong>Licence</strong></p>

                        <p>1. Under this End User License Agreement (the "Agreement"), Hockey Pipeline (the "Vendor") grants to the user (the "Licensee") a non-exclusive and non-transferable license (the "Licence") to use hockeypipeline.com (the "website").</p>

                        <p>2. "Website" includes the executable computer programs and any related printed, electronic and online documentation and any other files that may accompany the product.</p>

                        <p>3. Title, copyright, intellectual property rights and distribution rights of the Website remain exclusively with the Vendor. Intellectual property rights include the look and feel of the Website. This Agreement constitutes a license for use only and is not in any way a transfer of ownership rights to the Website.</p>

                        <p>4. The Website may not be modified, reverse-engineered, or de-compiled in any manner through current or future available technologies.</p>

                        <p>5. Failure to comply with any of the terms under the Licence section will be considered a material breach of this Agreement.</p>

                        <p><strong>Limitation of Liability</strong></p>

                        <p>6. The Website is provided by the Vendor and accepted by the Licensee "as is". Liability of the Vendor will be limited to a maximum of the original purchase price of the Website. The Vendor will not be liable for any general, special, incidental or consequential damages including, but not limited to, loss of production, loss of profits, loss of revenue, loss of data, or any other business or economic disadvantage suffered by the Licensee arising out of the use or failure to use the Website.</p>

                        <p>7. The Vendor makes no warranty expressed or implied regarding the fitness of the Website for a particular purpose or that the Website will be suitable or appropriate for the specific requirements of the Licensee.</p>

                        <p>8. The Vendor does not warrant that use of the Website will be uninterrupted or error-free. The Licensee accepts that website in general is prone to bugs and flaws within an acceptable level as determined in the industry.</p>

                        <p><strong>Acceptance</strong></p>

                        <p>9. All terms, conditions and obligations of this Agreement will be deemed to be accepted by the Licensee ("Acceptance") on registration of the Website with the Vendor.</p>

                        <p><strong>Term</strong></p>

                        <p>10. The term of this Agreement will begin on Acceptance and is perpetual.</p>

                        <p><strong>Termination</strong></p>

                        <p>11. This Agreement will be terminated and the Licence forfeited where the Licensee has failed to comply with any of the terms of this Agreement or is in breach of this Agreement. On termination of this Agreement for any reason, the Licensee will promptly destroy the Website or return the Website to the Vendor.</p>

                        <p><strong>Miscellaneous</strong></p>

                        <p>12. This Agreement can only be modified in writing signed by both the Vendor and the Licensee.</p>

                        <p>13. This Agreement does not create or imply any relationship in agency or partnership between the Vendor and the Licensee.</p>

                        <p>14. Headings are inserted for the convenience of the parties only and are not to be considered when interpreting this Agreement. Words in the singular mean and include the plural and vice versa. Words in the masculine gender include the feminine gender and vice versa. Words in the neutral gender include the masculine gender and the feminine gender and vice versa.</p>
                        <p>15. If any term, covenant, condition or provision of this Agreement is held by a court of competent jurisdiction to be invalid, void or unenforceable, it is the parties' intent that such provision be reduced in scope by the court only to the extent deemed necessary by that court to render the provision reasonable and enforceable and the remainder of the provisions of this Agreement will in no way be affected, impaired or invalidated as a result.</p>
                        <p>16. This Agreement contains the entire agreement between the parties. All understandings have been included in this Agreement. Representations which may have been made by any party to this Agreement may in some way be inconsistent with this final written Agreement. All such statements are declared to be of no value in this Agreement. Only the written terms of this Agreement will bind the parties.</p>
                        <p>17. This Agreement and the terms and conditions contained in this Agreement apply to and are binding upon the Vendor's successors and assigns.</p>
                        <p><strong>Notices</strong></p>
                        <p>18. All notices to the Vendor under this Agreement are to be provided at the following address:
                            Hockey Pipeline: hockeypipeline@gmail.com</p>

                        <p><strong>II. Terms of Use</strong></p>
                        <p>By accessing and using hockeypipeline.com and any other site, application or embedded content owned or operated by hockeypipeline (the “Website”), you accept and agree to be bound by the following terms and conditions (“Terms”):
                        Use: You may only use the Website in accordance with these Terms. All rights not expressly granted to you in these Terms are reserved by us.
                        Responsibility: You will be responsible for all activity that occurs as a result of your use of the Website. We disclaim any and all liability (including for negligence) for the content, opinions, statements or other information posted to, or use of, the Website by its users.
                        Community policy: You must be courteous and respectful of others' opinions, and you must not post unwelcome, aggressive, suggestive or otherwise inappropriate remarks directed at another member of the Website.
                        No spam or multiple accounts: You must not use the Website or encourage others to use the Website to create multiple accounts, deceive or mislead other users, disrupt discussions, game the Website's mechanics, alter consensus, post spam or otherwise violate our community policy.
                        No malicious use: You agree to access the Website through the interface we provide. You must not use the Website for any malicious means, or abuse, harass, threaten, intimidate or impersonate any other user of the Website. You must not request or attempt to solicit personal or identifying information from another member of the Website.
                        No illegal use: You must not use the Website for any unlawful purpose, or post any information that is in breach of any confidentiality obligation, copyright, trademark or other intellectual property or proprietary rights of any person.
                        You must not use the Website to promote, engage in or incite hate, violence or intolerance based on race, age, gender, gender identity, ethnicity, religion or sexual orientation.
                        Removal of content: We reserve the right to remove any content posted to the Website which we consider (in our absolute discretion) to be offensive, objectionable, unlawful or otherwise in breach of these Terms.
                        Intellectual property: You agree that we own all of the intellectual property rights in the Website. You grant us a non-exclusive, royalty-free, irrevocable, perpetual and sub-licensable right to use, reproduce, distribute and display (including for commercial purposes) on the Website and in other media any content or material that you post on the Website, and any name(s) and/or avatar under which you post such content. Other than this right, we claim no intellectual property rights in relation to the information or content that you upload onto the Website. Any content you post to the Website should be original, and not infringe anyone else’s intellectual property rights. You warrant that you own or are authorized to use and publish any content that you post.
                        Indemnity: You indemnify, and will keep indemnified, us against all forms of liability, actions, proceedings, demands, costs, charges and expenses which we may however incur or be subject to or suffer as a result of the use by you of the Website.
                        Amendments: We reserve the right to amend these Terms at any time, including by changing the amount of any Fees payable for any of our services, and may also add new features that will be subject to these Terms. By continuing to use the Website, you will be taken to have agreed to the changes.
                        Third-party applications: We may provide a platform for third parties' applications, websites and services to make products and services available to you (“Third Party Applications”) and your use of any Third Party Applications will be subject to their terms of use. You agree that we will not be liable for the behavior, features or content of any Third Party Applications.
                        Termination or suspension of accounts: If you do not abide by these Terms, we may terminate or suspend your account.
                        Technical support and malfunctions: We will try to promptly address (during normal business hours) all technical issues that arise on the Website. However, we will not be liable for any loss suffered as a result of any partial or total breakdown of the Website or any technical malfunctions.
                        Governing law and jurisdiction: All users of the Website agree that the laws of Ontario, Canada shall govern and apply to these Terms and each user's use of the Website, and all users submit to the exclusive jurisdiction of the Ontario courts for any matter or dispute arising in relation to these Terms.</p>

                        <p><strong>III. Privacy Policy</strong></p>
                        
                        <p>Application: This Privacy Policy applies to all personal information submitted by you on hockeypipeline.com (the “Website”) and any information that may be automatically retrieved through your use of the Website.
                        Consent: By accessing and using the Website, you consent to the collection, use, disclosure, storage and processing of your information in accordance with this Privacy Policy.
                        Changes to Privacy Policy: We may amend or update this Privacy Policy from time to time, with or without notice to you. You agree to be bound by the Privacy Policy that is in effect at the time you access and use the Website.
                        Personal information: In order to use particular services that we offer, you may need to submit certain personal information such as your email address, name, address, telephone number, gender and date of birth. You may be asked to submit further information from time to time. If you connect your Facebook or Twitter accounts to your account with hockeypipeline, we will access these accounts to identify which of your friends on those services are also using hockeypipeline.
                        Use of information: The personal information you provide us will only be used in relation to the services we provide you, to communicate with you in relation to our services or to co-operate with any government, industry or regulatory authorities.
                        Access to and updates of information: You may request at any time to see the personal information that we hold on your behalf or to correct or update any of your personal information (to the extent that you are unable to do so yourself on the Website).
                        Security: You must keep any login, password or account information relating to your use of the Website secure at all times, and must immediately notify us of any unauthorized use of such information or any other breach of security. We will not be liable for any loss or damage if you fail to comply with this security obligation.</p>

                    </Typography>
                </Grid>
            </Grid>
        </Container>
    );
};

export default TermsOfService;
