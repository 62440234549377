import { format } from "date-fns";

const playerColumns = [
    {
        accessorKey: 'consensus_ranking',
        header: 'Ranking',
        size: 20, //small column
    },
    {
        accessorKey: 'first_name',
        header: 'Name',
        Cell: ({ row }) => { return (row.original.first_name + " " + row.original.last_name) },
        size: 20, //small column

    },
    {
        accessorKey: 'country.country_name',
        header: 'Country',
        size: 50, //small column
    },
    {
        accessorKey: 'dob',
        header: 'D.O.B.',
        size: 50, //small column
        Cell: ({ cell }) => { return format(new Date(cell.getValue()), "MM-dd-yyyy") },
    },
    {
        accessorKey: 'position',
        header: 'Position',
        size: 50, //small column
    },
    {
        accessorKey: 'height',
        header: 'Height',
        size: 50, //small column
    },
    {
        accessorKey: 'weight',
        header: 'Weight',
        size: 50, //small column
    },
    {
        accessorKey: 'handedness',
        header: 'Handedness',
        size: 50, //small column
    },
]

export { playerColumns };