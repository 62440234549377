import React, { useState, useEffect } from "react";
import { Typography, Grid, Select, MenuItem, Button, InputLabel, FormControl } from '@mui/material';
import { Container } from "@mui/system";
import { useSearchParams } from "react-router-dom";
import BlogPostCard from "../../components/BlogPostCard/BlogPostCard";
import axios from "axios";


function BlogPosts() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [blogPosts, setBlogPosts] = useState(null);
    const [authors, setAuthors] = useState([]);
    const [author, setAuthor] = useState(searchParams.get('author_id'));
    const [error, setError] = useState(null);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "/blog_posts", { params: { "author_id": author } }).then((response) => {
            setBlogPosts(response.data);
        })
            .catch((error) => {
                setError(error);
            });

    }, [author]);

    useEffect(() => {
        if (searchParams.get('author_id')) {
            setAuthor(searchParams.get('author_id'));
        };
    }, [author]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "/authors").then((response) => {
            setAuthors(response.data);
        })
            .catch((error) => {
                setError(error);
            });
    }, []);

    const handleAuthorChange = (event) => {
        setAuthor(event.target.value);
        setSearchParams({ author_id: event.target.value });
    };

    const clearFilters = () => {
        setAuthor("");
        setSearchParams();
    };


    useEffect(() => {
        document.title = 'Blog Posts - Hockey Pipeline';
    }, []);

    return (
        <Container Container className="container top-container">
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="h4" component="div" className="page-title">
                        Blog Posts
                    </Typography>
                    <div className="title-underline" />
                    <Grid container spacing={2} justifyContent="right">
                        <Grid item xs={12} sm={6} md={3} justify="center" alignItems="center" direction="row" >
                            <InputLabel className="drop-down-label">Author</InputLabel>
                            <Select
                                value={author}
                                autoWidth
                                label="Author"
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                className="drop-down"
                                onChange={handleAuthorChange}
                                size="small"
                            >
                                {authors.map((author) => (
                                    <MenuItem key={author.author_id} value={author.author_id}>
                                        {author.author_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={3} md={2} className="clear-btn-container" >
                            <Button
                                variant="outlined"
                                className="clear-btn"
                                onClick={clearFilters}
                            >
                                Clear Filter
                            </Button>
                        </Grid>
                    </Grid>
                    {blogPosts && blogPosts.map((blogPost) => (
                        <Grid item xs={12} sm={12} md={12}>
                            <BlogPostCard blogPost={blogPost} />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Container>
    );
};

export default BlogPosts;