import React from "react";
import { Typography } from '@mui/material';
import { Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Scatter, ComposedChart, ResponsiveContainer } from 'recharts';
import { format } from "date-fns";
import PlayerComparisonGraphToolTip from "./PlayerComparisonGraphToolTip";
import { el, es } from "date-fns/locale";


function PlayerComparisonGraph({ graphDataOne, graphDataTwo, playerOneData, playerTwoData}) {
    function dateFormatter(tickItem) {
        return format(new Date(tickItem), "MMM yyyy");
    }

    function getDomain(graphDataOne, graphDataTwo) {
        if ((graphDataOne != null && graphDataOne.length > 0) && (graphDataTwo != null && graphDataTwo.length > 0)) {
            const maxDate =  Math.max(graphDataOne[graphDataOne.length - 1].effective_date, graphDataTwo[graphDataTwo.length - 1].effective_date)
            const minDate =  Math.min(graphDataOne[0].effective_date, graphDataTwo[0].effective_date)

            return [maxDate, minDate]
        }
        else if (graphDataOne != null && graphDataOne.length > 0){
            return [graphDataOne[0].effective_date, graphDataOne[graphDataOne.length - 1].effective_date]
        }

        else if (graphDataTwo != null && graphDataTwo.length > 0){
            return [graphDataTwo[0].effective_date, graphDataTwo[graphDataTwo.length - 1].effective_date]
        }
        else {
            return [0, 0]
        }
    }

    const playerNameOne = playerOneData ? playerOneData.first_name + " " + playerOneData.last_name : "";
    const playerNameTwo = playerTwoData ? playerTwoData.first_name + " " + playerTwoData.last_name : "";

    return (<>
        <Typography variant="h4" component="div" style={{ textAlign: "center", paddingTop: 30, marginBottom: 10 }}>
            Rank Score Comparison
        </Typography>
        <ResponsiveContainer width="99%" aspect={1.6}>
            <ComposedChart width={700} height={200} data={graphDataOne} margin={{ top: 10, left: 20, right: 50, bottom: 20 }}>
                <CartesianGrid strokeDasharray="1" />
                <XAxis 
                    type="number" 
                    domain={getDomain(graphDataOne, graphDataTwo)}
                    dataKey="effective_date" 
                    tickFormatter={dateFormatter} 
                    allowDuplicatedCategory={false} 
                    stroke="white"
                    interval="preserveStartEnd"
                    tickCount={8}
                />
                <YAxis reversed={true} tickCount={10} domain={[1, 'dataMax + 10']} stroke="white" />
                <Legend align="center" />
                <Tooltip content={<PlayerComparisonGraphToolTip/>} />
                {(graphDataTwo != null && graphDataTwo.length > 0) && <Line data={graphDataTwo} dataKey="score" fill="#49beaa99" name={playerNameTwo + " - Ranking Score"} stroke="#49beaa" type="monotone" dot={false} strokeWidth={3} /> }
                {(graphDataOne != null && graphDataOne.length > 0) && <Line data={graphDataOne} dataKey="score" fill="#ef767a" name={playerNameOne + " - Ranking Score"} stroke="#ef767a" type="monotone" dot={false} strokeWidth={3} />}

            </ComposedChart>
        </ResponsiveContainer>
    </>
    );
};

export default PlayerComparisonGraph;