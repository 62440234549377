import React from 'react';
import Home from './pages/Home/Home';
import Player from './pages/Player/Player';
import MockDraft from './pages/MockDraft/MockDraft';
import Ranking from './pages/Ranking/Ranking';
import ConsensusRankings from './pages/ConsensusRankings/ConsensusRankings';
import MocksAndRankings from './pages/MocksAndRankings/MocksAndRankings';
import BlogPost from './pages/BlogPost/BlogPost';
import BlogPosts from './pages/BlogPosts/BlogPosts';
import AboutUs from './pages/AboutUs/AboutUs';
import Players from './pages/Players/Players';
import Team from './pages/Team/Team';
import NavBar from './components/NavBar/NavBar';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Footer from './components/Footer/Footer';
import DraftOrder from './pages/DraftOrder/DraftOrder';
import TermsOfService from './pages/TermsOfService/TermsOfService';
import PlayerComparison from './pages/PlayerComparison/PlayerComparison';
import DraftYearPicker from './components/DraftYearPicker/DraftYearPicker';
import { DraftYearProvider} from './contexts/DraftYearContext';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import './App.css';

function App() {
  return (
    <Router >
      <ScrollToTop />
      <div id="page-container">
        <div id="content-wrap">
          <DraftYearProvider>
            <NavBar />
            <DraftYearPicker />
            <Routes path="/">
              <Route exact path="" element={
                <Home />
              }></Route>
              <Route exact path="consensus-rankings" element={
                <ConsensusRankings />
              }></Route>
              <Route exact path="player/:player_id" element={
                <Player />
              }></Route>
              <Route exact path="mocks-and-rankings" element={
                <MocksAndRankings />
              }></Route>
              <Route exact path="mock-draft/:mock_draft_id" element={
                <MockDraft />
              }></Route>
              <Route exact path="ranking/:ranking_id" element={
                <Ranking />
              }></Route>
              <Route exact path="team/:team_id" element={
                <Team />
              }></Route>
              <Route exact path="players" element={
                <Players />
              }></Route>
              <Route exact path="blog-post/:blog_post_id" element={
                <BlogPost />
              }></Route>
              <Route exact path="blog" element={
                <BlogPosts />
              }></Route>
              <Route exact path="draft-order" element={
                <DraftOrder />
              }></Route>
              <Route exact path="about-us" element={
                <AboutUs />
              }></Route>
              <Route exact path="player-comparison" element={
                <PlayerComparison />
              }></Route>
              <Route exact path="terms-of-service" element={
                <TermsOfService />
              }></Route>
            </Routes>
          </DraftYearProvider>
        </div>
        <Footer />
      </div>
    </Router>
  )
}

export default App;
