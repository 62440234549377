const nhlTeamColors = {
    anaheim_ducks: "#f47a38",
    arizona_coyotes: "#8c2633",
    boston_bruins: "#fcb514",
    buffalo_sabres: "#002654",
    calgary_flames: "#cf4521",
    carolina_hurricanes: "#cc0000",
    chicago_blackhawks: "#cf0a2c",
    colorado_avalanche: "#6f263d",
    columbus_blue_jackets: "#002654",
    dallas_stars: "#006847",
    detroit_red_wings: "#ce1126",
    edmonton_oilers: "#041e42",
    florida_panthers: "#041e42",
    los_angeles_kings: "#111111",
    minnesota_wild: "#154734",
    montreal_canadiens: "#af1e2d",
    nashville_predators: "#ffb81c",
    new_jersey_devils: "#ce1126",
    new_york_islanders: "#00539b",
    new_york_rangers: "#0038a8",
    ottawa_senators: "#e31837",
    philadelphia_flyers: "#f74902",
    pittsburgh_penguins: "#000000",
    san_jose_sharks: "#006d75",
    seattle_kraken: "#005bea",
    st_louis_blues: "#002f87",
    tampa_bay_lightning: "#002868",
    toronto_maple_leafs: "#00205b",
    vancouver_canucks: "#00205b",
    vegas_golden_knights: "#b4975a",
    washington_capitals: "#041e42",
    winnipeg_jets: "#041e42"
};


export { nhlTeamColors }