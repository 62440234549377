import React, { useState, useEffect, useContext } from "react";
import { Typography, Grid, Container } from '@mui/material';
import PlayerCard from "../../components/PlayerCard/PlayerCard";
import { DraftYearContext } from "../../contexts/DraftYearContext";
import axios from "axios";
import "./ConsensusRankings.css"
import PageLoader from "../../components/PageLoader/PageLoader";


function ConsensusRankings() {
    const [players, setPlayers] = useState(null);
    const [error, setError] = useState(null);
    const { draftYear, setDraftYear } = useContext(DraftYearContext);

    // Loading States
    const [loading, setLoading] = useState(true);
    const [draftYearChange, setDraftYearChange] = useState(true);
    const [prevDraftYear, setPrevDraftYear] = useState(null);

    document.title = "Consensus Rankings - Hockey Pipeline";

    useEffect(() => {
        setLoading(true);
        axios.get(process.env.REACT_APP_API_URL + "/players", { params: { "draft_year": draftYear } }).then((response) => {
            setPlayers(response.data);
            setLoading(false);
        })
            .catch((error) => {
                setError(error);
            });
    }, [draftYear]);

    useEffect(() => {
        if (prevDraftYear == null) {
          setPrevDraftYear(draftYear);
        }
        else {
          if (prevDraftYear != draftYear) {
            setDraftYearChange(false);
          }
        }
        setPrevDraftYear(draftYear);
      }, [draftYear]);

    function getIsLoading() {
        return loading && !draftYearChange;
    }

    return (
        <>
        <PageLoader loading={getIsLoading()}/>
            <Container className="container top-container">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography variant="h4" component="div" className="page-title">
                            Consensus Rankings
                        </Typography>
                        <div className="title-underline" />
                    </Grid>
                    <Grid container spacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} rowSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        {players && players.map((player) => (
                            <Grid item xs={6} sm={6} md={3} lg={12 / 5} spacing={4}>
                                <PlayerCard playerData={player} miniView={true} />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default ConsensusRankings;