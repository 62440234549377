import React, { useState, useEffect } from "react";
import { Typography, Grid } from '@mui/material';
import { Container } from "@mui/system";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { Link } from 'react-router-dom';

import axios from "axios";
import PlayerCard from "../../components/PlayerCard/PlayerCard";


function Ranking() {
    const [ranking, setRanking] = useState(null);
    const [error, setError] = useState(null);

    const { ranking_id } = useParams();
    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "/rankings/" + ranking_id).then((response) => {
            setRanking(response.data);
            document.title = response.data.title + ' - Hockey Pipeline';
        })
            .catch((error) => {
                setError(error);
            });
    }, [ranking_id]);


    return (
        <>
            <Container className="container top-container">
                {ranking && <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography variant="h4" component="div">
                            {ranking.title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography className="sub-header-text">
                            {`Creation Date: ${format(new Date(ranking.creation_date), "MMM do, yyyy")}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography className="sub-header-text" sx={{ mb: 1 }}>
                            Author: <Link to={"../mocks-and-rankings?scouts_id=" + ranking.scouts_id} className="link-default">{ranking.scout.name}</Link>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography className="sub-header-text" sx={{ mb: 1 }}>
                            Publication: <Link to={"../mocks-and-rankings?publication_id=" + ranking.publication.publication_id} className="link-default">{ranking.publication.name}</Link>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography className="sub-header-text">
                            Draft Year: {ranking.draft_year}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className="sub-header-text">
                            If you want to see the full ranking, click <Link to={ranking.url} className="link-default">here</Link>.
                        </Typography>
                    </Grid>
                    {ranking && ranking.rankings.map((ranked_player) => (
                        <Grid item xs={6} sm={6} md={3} lg={12 / 5} spacing={4}>
                            <PlayerCard playerData={ranked_player.player} ranking={ranked_player.rank} rankTitle={"Rank"} miniView={true} />
                        </Grid>
                    ))}
                </Grid>}
            </Container>
        </>
    );
};

export default Ranking;