import React, { useState, useEffect, useMemo } from "react";
import { Typography, Grid } from '@mui/material';

function PlayerDraftOdd({ draftProb }) {
    return (
        <div className="draft-odds-data">
            <Typography variant="h4" component="div" className="draft-odds-data-title">
                {draftProb.overall_pick > 0 && <div className="player-draft-pick-row">
                    <div className="draft-number-group">
                        <div className="draft-pick-text">
                            Round
                        </div>
                        <div className="draft-pick-number">
                            {draftProb.round}
                        </div>
                    </div>
                    <div className="draft-number-group">
                        <div className="draft-pick-text">
                            Pick
                        </div>
                        <div className="draft-pick-number">
                            {draftProb.pick_num}
                        </div>
                    </div>
                    <div className="draft-number-group">
                        <div className="draft-pick-text">
                            Team
                        </div>
                        <div className="draft-pick-logo">
                            <img src={draftProb.ultimate_team_logo} className="team-logo" ></img>
                        </div>
                    </div>
                    <div className="draft-number-group">
                        <div className="draft-pick-text">
                            Odds
                        </div>
                        <div className="draft-pick-number">
                            {(draftProb.probability * 100).toFixed(1)}%
                        </div>
                    </div>
                </div>}
                {draftProb.pick < 0 && <div className="player-draft-pick-row">
                    <div className="out-of-top-96">
                        Drafted after 3rd round
                    </div>
                    <div className="draft-number-group">
                        <div className="draft-pick-text">
                            Odds
                        </div>
                        <div className="draft-pick-number">
                            {(draftProb.probability * 100).toFixed(1)}%
                        </div>
                    </div>
                </div>}
            </Typography>
        </div>
    )
}

export default PlayerDraftOdd;