import React, { useState, useEffect } from "react";
import PlayerCard from "../../components/PlayerCard/PlayerCard";
import { Typography, Grid } from '@mui/material';
import { Container } from "@mui/system";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { Link } from 'react-router-dom';

import axios from "axios";


function MockDraft() {
    const [mock, setMock] = useState(null);
    const [error, setError] = useState(null);

    const { mock_draft_id } = useParams();
    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "/mock_drafts/" + mock_draft_id).then((response) => {
            setMock(response.data);
            document.title = response.data.title + ' - Hockey Pipeline';

        })
            .catch((error) => {
                setError(error);
            });
    }, [mock_draft_id]);

    useEffect(() => {
        document.title = 'Mock Drafts - Hockey Pipeline';
      }, []);

    return (
        <Container className="container top-container">
            {mock && <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="h4" component="div">
                        {mock.title}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Typography className="sub-header-text">
                        {`Creation Date: ${format(new Date(mock.creation_date), "MM-dd-yyyy")}`}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Typography className="sub-header-text" sx={{ mb: 1 }}>
                        Author: <Link to={"../mocks-and-rankings?scouts_id=" + mock.scouts_id} className="link-default">{mock.scout.name}</Link>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Typography className="sub-header-text" sx={{ mb: 1 }}>
                        Publication: <Link to={"../mocks-and-rankings?publication_id=" + mock.publication.publication_id} className="link-default">{mock.publication.name}</Link>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Typography className="sub-header-text">
                        Draft Year: {mock.draft_year}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Typography className="sub-header-text">
                        If you want to see the full mock draft, click <Link to={mock.url} className="link-default">here</Link>.
                    </Typography>
                </Grid>
                {mock.mock_picks.map((mock_pick) => (
                    <Grid item xs={6} sm={6} md={3} lg={12 / 5} spacing={4}>
                            <PlayerCard playerData={mock_pick.player} ranking={mock_pick.rank} rankTitle={"Pick"} nhlTeam={mock_pick.team} miniView={true}/>
                    </Grid>
                ))}
            </Grid>}

        </Container>
    );
};

export default MockDraft;