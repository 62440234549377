import React from "react";
import { Typography, Card, CardMedia, Grid } from '@mui/material';
import { format } from "date-fns";
import { Link } from 'react-router-dom';

import "./TeamDraftYear.css"
import PlayerCard from "../PlayerCard/PlayerCard";
import { el } from "date-fns/locale";

function TeamDraftYear({ draft_year, draft_grade }) {

    function pickAndRoundToOverall(pick, round, year) {
        var team_count = 30
        if (year >= 2021) {
            team_count = 32
        }
        else if (year >= 2017) {
            team_count = 31
        }
        return pick + (round - 1) * team_count
    }

    function getTopPicks(picks) {
        if (picks.length > 3) {
            return picks.slice(0, 4)
        }
        else {
            return picks
        }
    }

    function getRemainingPicks(picks) {
        if (picks.length > 3) {
            return picks.slice(4, picks.length)
        }
        else {
            return []
        }
    }


    return (
        <Grid item xs={12} sm={12} md={12}  >
            <div className="container">
                <div className="team-page-header">
                    <Typography variant="body1" className="draft_year">
                        {draft_year.draft_year} Draft Results
                    </Typography>
                </div>
                <div className="team-page-header">
                { draft_grade && <Typography variant="body1" className="draft_grade">
                        <div className="grade_text">Grade:</div> {draft_grade} 
                    </Typography>}    
                </div>

                <Grid container spacing={2} padding={2}>
                    {draft_year && getTopPicks(draft_year.picks).map((draft_pick) => (
                        <Grid item xs={6} sm={4} md={3}>
                            {draft_pick && <PlayerCard playerData={draft_pick} ranking={pickAndRoundToOverall(draft_pick.pick, draft_pick.round, draft_pick.draft_year)} />}
                        </Grid>
                    ))}
                </Grid>
                <Grid container spacing={2} padding={2}>
                    {draft_year && getRemainingPicks(draft_year.picks).map((draft_pick) => (
                        <Grid item xs={12} sm={12} md={12}>
                            {draft_pick &&
                                <Link  to={"../player/" + draft_pick.player_id}  className="lesser_pick">
                                        <Typography variant="body1">
                                            {pickAndRoundToOverall(draft_pick.pick, draft_pick.round, draft_pick.draft_year)}.
                                        </Typography>
                                        <Typography variant="body1" className="player-name">
                                            {draft_pick.first_name} {draft_pick.last_name}
                                        </Typography>
                                        <div className="sub-heading-divider"></div>
                                        <Typography variant="body1" className="sub-info">
                                            {draft_pick.country_name}
                                        </Typography>
                                        <div className="sub-heading-divider"></div>
                                        <Typography variant="body1" className="sub-info">
                                            {draft_pick.position}
                                        </Typography>
                                        <div className="sub-heading-divider"></div>
                                        <Typography variant="body1" className="sub-info">
                                            {draft_pick.weight && draft_pick.weight + " lbs"}
                                        </Typography>
                                        <div className="sub-heading-divider"></div>
                                        <Typography variant="body1" className="sub-info">
                                            {draft_pick.height && draft_pick.height}
                                        </Typography>
                                </Link>
                            }
                        </Grid>
                    ))}
                </Grid>
            </div>
        </Grid>
    )

}


export default TeamDraftYear;