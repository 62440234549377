import React from 'react';
import Typography from '@mui/material/Typography';
import "./Footer.css"
import { Link } from 'react-router-dom';

const Footer = () => {
    return (

        <div className="footer">
            <Typography className='footer-nav' variant="body2" color="text.secondary" align="center">
                <Link to="/" className="footer-link">Home</Link>
                <div className="footer-split"/>
                <Link to="/consensus-rankings" className="footer-link">Consensus Ranking</Link>
                <div className="footer-split"/>
                <Link to="/mocks-and-rankings" className="footer-link">Mocks & Rankings</Link>
                <div className="footer-split"/>
                <Link to="/players" className="footer-link">Draft Prospects</Link>
                <div className="footer-split"/>
                <Link to="/blog" className="footer-link">Blog</Link>
                <div className="footer-split"/>
                <Link to="/about-us" className="footer-link">About Us</Link>
                <div className="footer-split"/>
                <Link to="/terms-of-service" className="footer-link">Terms of Service</Link>

            </Typography>
            <Typography className="copy-right" variant="body2" color="text.secondary" align="center">
                © {new Date().getFullYear()} Hockey Pipeline
            </Typography>
        </div>
    );
};

export default Footer;