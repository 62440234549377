import React, { useEffect } from "react";
import { Container, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';


const AboutUs = () => {
    useEffect(() => {
        document.title = 'About Us - Hockey Pipeline';
    }, []);

    return (
        <Container className="container top-container" >
            <Grid container columnSpacing={4}>
                <Grid item xs={12} sm={12} md={12} >
                    <Typography variant="h4" component="div" className="page-title">
                        About Us
                    </Typography>
                    <div className="title-underline" />
                </Grid>
                <Grid item xs={12} sm={12} md={12} >
                    <Typography variant="body1" paragraph>
                        <b>Welcome to Hockey Pipeline!</b>
                        <br/>
                        <br/>
                        hockeypipeline.com is a hockey prospect statistics website originally launched in June 2023 by Daniel and Jared.
                        <br/>
                        This website was built to provide deeper insights into prospects pre-draft and post-draft as they try to become successful NHLers.
                        <br/>
                        All data is subject to change based on new data imports or methodology changes.
                        <br/>
                        This website and its content have no direct affiliation to the NHL, NHLPA, or any other team/league mentioned on this domain.
                        <br/>
                        <br/>
                        If you have any questions, concerns or requests, here’s how we can be reached:
                        <br/>
                        
                        Twitter: <Link to={"https://twitter.com/hockeypipeline"}  target="_blank">@hockeypipeline</Link>
                        <br/>
                        Email: hockeypipeline@gmail.com

                    </Typography>
                </Grid>
            </Grid>
        </Container>
    );
};

export default AboutUs;
