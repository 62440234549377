import React, { useState, createContext, useEffect } from 'react';

const DraftYearContext = createContext();

const DraftYearProvider = ({ children }) => {
  // Check if there's a saved draftYear value in localStorage
  const savedDraftYear = localStorage.getItem('draftYear');

  // Initialize the state with the saved value or the default (2024)
  const [draftYear, setDraftYear] = useState(savedDraftYear ? parseInt(savedDraftYear, 10) : 2024);

  // Update localStorage whenever draftYear changes
  useEffect(() => {
    localStorage.setItem('draftYear', draftYear.toString());
  }, [draftYear]);

  return (
    <DraftYearContext.Provider value={{ draftYear, setDraftYear }}>
      {children}
    </DraftYearContext.Provider>
  );
};

export { DraftYearContext, DraftYearProvider };