import React from "react";
import { Typography, Card, CardContent, Chip } from '@mui/material';
import { format } from "date-fns";
import { Link } from 'react-router-dom';
import "./RankMockCard.css"

function RankMockCard({ rankMock }) {
    var date = new Date(rankMock.creation_date);
    var formattedDate = format(date, "MMM do, yyyy");

    return (
        <Card className="rank-mock-card">
            <CardContent>
                <Chip label={(rankMock.rank_type == "ranking") ? "Ranking" : "Mock Draft"} size="small" color={(rankMock.rank_type == "ranking") ? "primary" : "success"} variant="outlined" />
                <Typography variant="h5" component="div" className="rank-mock-header">
                    <Link to={((rankMock.rank_type === "mock_draft") ? "../mock-draft/" : "../ranking/") + rankMock.data_point_id}
                        style={{ color: "#1976d2", fontSize: "24px" }}>
                        {rankMock.title}
                    </Link>

                </Typography>
                <div className="rank-mock-subheader">
                    <Typography color="textSecondary" sx={{ mb: 1 }}>
                        {formattedDate}
                    </Typography>
                    <div className="sub-heading-divider"></div>
                    <Typography color="textSecondary" sx={{ mb: 1 }}>
                        <Link reloadDocument={true} to={"../mocks-and-rankings?scouts_id=" + rankMock.scouts_id} style={{ color: "#1976d2" }}> {rankMock.scout_name}</Link>
                    </Typography>
                    <div className="sub-heading-divider"></div>
                    <Typography color="textSecondary" sx={{ mb: 1 }}>
                        <Link reloadDocument={true} to={"../mocks-and-rankings?publication_id=" + rankMock.publication_id} style={{ color: "#1976d2" }}> {rankMock.publication_name}</Link>
                    </Typography>
                    <div className="sub-heading-divider"></div>
                    <Typography color="textSecondary">
                        Draft Year: {rankMock.draft_year}
                    </Typography>
                </div>
            </CardContent>
            <div className="rank-mock-card-divider"></div>
        </Card>

    );
};

export default RankMockCard;