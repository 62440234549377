import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import Popper from '@mui/material/Popper';
import { useTheme, styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./SearchBar.css"

function SearchBar() {
  const [allOptions, setAllOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const navigate = useNavigate();

  const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
      boxSizing: 'border-box',
      backgroundColor: '#1e1e1e',
      color: 'white',
      border: 'solid 1px white',
      padding: '0px',

      '& li:hover': {
        backgroundColor: '#424242',
      },
      '& li': {
        borderBottom: 'solid 1px white',
      }
    },
  });


  // ONLY QUERY API IF INPUT VALUE IS 3 CHARACTERS, OTHERWISE FILTER OPTIONS LOCALLY
  const handleInputChange = (event, value) => {
    setInputValue(event.target.value);
    if (event.target.value.length == 3) {
      // Call API to populate dropdown options
      fetchOptions(event.target.value);
    } else if (event.target.value.length > 3) {
      const constructedPlayerId = event.target.value.replace(/\s/g, '_').toLowerCase();
      setOptions(allOptions.filter((option) => option.player_id.includes(constructedPlayerId)));
    }
    else {
      setOptions([]); // Clear options if less than 3 characters
    }
  };

  const fetchOptions = async (value) => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + "/players/search/" + value);
      setAllOptions(response.data);
      setOptions(response.data);
    } catch (error) {
      console.error('Error fetching options:', error);
    }
  };


  return (
    <Autocomplete
      className="autocomplete-search-bar"
      PopperComponent={StyledPopper}
      options={options}
      getOptionLabel={(option) => (option.first_name + " " + option.last_name)}
      onChange={(event, option) => {
        setOptions([]);
        if (option){
          navigate('/player/' + option.player_id);
        }
        
      }
      }
      renderInput={(params) => (
        <TextField
          className="search-bar"
          {...params}
          label="Player Search"
          value={inputValue}
          onChange={handleInputChange}
        />
      )}
    />
  );
};


export default SearchBar;