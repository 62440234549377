import React, { useState } from "react"
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import SearchBar from '../SearchBar/SearchBar';
import { Link, useLocation } from 'react-router-dom';

import "./NavBar.css"

const pages = [
    { 'title': 'Home', 'path': '' },
    { 'title': 'Consensus Rankings', 'path': 'consensus-rankings' },
    { 'title': 'Mocks and Rankings', 'path': 'mocks-and-rankings' },
    { 'title': 'Drafts', 'path': 'draft-order' },
    { 'title': 'Draft Prospects', 'path': 'players' },
    { 'title': 'Blog', 'path': 'blog' },
    { 'title': 'Player Comparison', 'path': 'player-comparison' }
];

function NavBar() {
    const [anchorElNav, setAnchorElNav] = useState("");
    const location = useLocation();

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };


    return (
        <AppBar position="static" sx={{
            backgroundColor: '#1e1e1e'
        }} className="nav-bar">
            <Container maxWidth={false}>
                <Toolbar disableGutters >
                    <Link to={"/"}>
                        <Box
                            component="img"
                            sx={{
                                display: { xs: 'flex', md: 'flex' },
                                height: 50,
                                width: 133,
                                maxHeight: { xs: 50, md: 150 },
                                maxWidth: { xs: 133, md: 400 },
                                position: "relative",
                                left: "-16px"
                            }}
                            alt="Hockey Pipeline"
                            src="/logo@4x.png"
                        />
                    </Link>

                    <Box sx={{ flexGrow: 300, justifyContent: "center", display: { s: 'flex', md: 'none' } }}>
                        <SearchBar />
                    </Box>
                    <Box sx={{ flexGrow: 1, justifyContent: "right", display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <Link to={"/" + page.path}>
                                    <MenuItem
                                        key={page.title}
                                        onClick={handleCloseNavMenu}
                                        className={location.pathname === "/" + page.path ? "active" : ""}
                                    >
                                        <Typography textAlign="center">{page.title}</Typography>
                                    </MenuItem>
                                </Link>
                            ))}
                        </Menu>
                    </Box>



                    <Box sx={{ flexGrow: 1, justifyContent: "left", display: { xs: 'none', md: 'flex' } }} className="nav-btns">
                        {pages.map((page) => (
                            <Link to={"/" + page.path} >
                                <Button
                                    key={page.path}
                                    onClick={handleCloseNavMenu}
                                    sx={{ my: 2, color: '#b0bec5', display: 'block' }}
                                    className={location.pathname === "/" + page.path ? "active" : ""}
                                >
                                    {page.title}
                                    <div hidden={location.pathname !== "/" + page.path} className="underline" />
                                </Button>

                            </Link>
                        ))}
                    </Box>

                    <Box sx={{ flexGrow: { md: 0.5, lg: 1.5, xl: 0.25 }, justifyContent: "right", display: { xs: 'none', md: 'flex' } }}>
                        <SearchBar />
                    </Box>

                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default NavBar;