import React, { useState, useContext } from "react";
import { InputLabel, Grid, Container, Select, MenuItem } from '@mui/material';
import { DraftYearContext } from "../../contexts/DraftYearContext";
import { useLocation } from 'react-router-dom';


function DraftYearPicker() {
    const { draftYear, setDraftYear } = useContext(DraftYearContext);
    const [draftYears, setDraftYears] = useState([2020, 2021, 2022, 2023, 2024]);

    const handleYearChange = (event) => {
        setDraftYear(event.target.value);
    };

    const location = useLocation();

    const pathsWithDraftYear = ["/", "/consensus-rankings", "/mocks-and-rankings", "/draft-order", "/players"];

    const isDraftYearPickerPage = () => {
        if (pathsWithDraftYear.includes(location.pathname)) {
            return true;
        }
        return false;
    };

    return (
        <div>
            {isDraftYearPickerPage() &&
                <Container className="draft-year-container" >
                    <Grid container spacing={2} className="draft-pick-detail" justifyContent="right">
                        <Grid item xs={12} sm={12} md={2}>
                            <InputLabel className="drop-down-label">Draft Year</InputLabel>
                            <Select
                                value={draftYear}
                                autoWidth
                                label="Draft Year"
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                className="drop-down"
                                onChange={handleYearChange}
                                size="small"
                            >
                                {draftYears.map((draftYear) => (
                                    <MenuItem key={draftYear} value={draftYear}>
                                        {draftYear}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>
                </Container>}
        </div>
    );
}

export default DraftYearPicker;