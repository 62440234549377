import React from "react";
import { Typography } from '@mui/material';
import { Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Scatter, ComposedChart, ResponsiveContainer } from 'recharts';
import { format } from "date-fns";
import PlayerGraphToolTip from "./PlayerGraphToolTip";

import "./PlayerGraph.css";


function PlayerGraph({ graphData }) {
    function dateFormatter(tickItem) {
        return format(new Date(tickItem), "MMM yyyy");
    }

    return (<>
        <Typography variant="h4" component="div" style={{ textAlign: "center", paddingTop: 30, marginBottom: 0 }}>
            Player Graph
        </Typography>
        <ResponsiveContainer width="99%" aspect={1.6}>
            <ComposedChart width={700} height={200} data={graphData} margin={{ top: 10, left: 20, right: 50, bottom: 20 }}>
                <CartesianGrid strokeDasharray="1" />
                <XAxis 
                    type="number" 
                    domain={[graphData[0].effective_date, graphData[graphData.length - 1].effective_date]}
                    dataKey="effective_date" 
                    tickFormatter={dateFormatter} 
                    allowDuplicatedCategory={false} 
                    stroke="white"
                    interval="preserveStartEnd"
                    tickCount={8}
                />
                <YAxis reversed={true} tickCount={10} domain={[1, 'dataMax + 10']} stroke="white" />
                <Legend align="center" />
                <Tooltip content={<PlayerGraphToolTip/>} />
                <Scatter data={graphData} dataKey="lastRank" fill="#49beaa99" name="Scout Ranking" />
                <Line data={graphData} dataKey="consensusRanking" fill="#49beaa99" name="Consensus Rank" stroke="#ef767a" type="monotone" dot={false} strokeWidth={3} />
            </ComposedChart>
        </ResponsiveContainer>
    </>
    );
};

export default PlayerGraph;