import React, { useState, useEffect, useMemo } from "react";
import { Typography, Grid } from '@mui/material';
import { useParams, useSearchParams } from "react-router-dom";
import moment from 'moment';

import axios from "axios";
import PlayerCard from "../../components/PlayerCard/PlayerCard";
import { Container } from "@mui/system";

import "./PlayerComparison.css"

function DataComparisonRow({ p1, p2, dataTitle, dataKey, greaterBetter, includeDecimal, isPercent, unit }) {

    function comparisonClass(playerOne, playerTwo, isHigherBetter, field) {
        if (playerOne && playerTwo) {
            if (isHigherBetter) {
                if (playerOne[field] > playerTwo[field]) {
                    return "comparison-winner";
                } else if (playerOne[field] < playerTwo[field]) {
                    return "comparison-loser";
                } else {
                    return "comparison-tie";
                }
            } else {
                if (playerOne[field] < playerTwo[field]) {
                    return "comparison-winner";
                } else if (playerOne[field] > playerTwo[field]) {
                    return "comparison-loser";
                } else {
                    return "comparison-tie";
                }
            }
        }
        return "failed"
    }

    function formatValue(value) {
        if (value === undefined || value === null) {
            return "N/A";
        }
        let new_value = value;
        if (isPercent) {
            new_value = (value.toFixed(2) * 100) + "%";
        }

        if (includeDecimal) {
            new_value = value.toFixed(2);
        }
        return new_value;
    }

    return (
        <div className="comparison-row">
            <div className="comparison-data-column-container">
                <Typography variant="h4" component="div" className={"comparison-data-column " + comparisonClass(p1, p2, greaterBetter, dataKey)}>
                    {p1 && formatValue(p1[dataKey])}    <div className="unit-format">{unit && p1 ? unit : ""}</div>
                </Typography>
            </div>
            <div className="comparison-data-title-container">
                <Typography variant="h4" component="div" className="comparison-title-column">
                    {dataTitle}
                </Typography>
            </div>
            <div className="comparison-data-column-container" >
                <Typography variant="h4" component="div" className={"comparison-data-column " + comparisonClass(p2, p1, greaterBetter, dataKey)}>
                    {p2 && formatValue(p2[dataKey])} <div className="unit-format">{unit && p2 ? unit : ""}</div>
                </Typography>
            </div>
        </div>
    );
};

export default DataComparisonRow;
