import React, { useState, useEffect } from "react";
import { Typography, Grid } from '@mui/material';
import { Container } from "@mui/system";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./Team.css";
import TeamDraftYear from "../../components/TeamDraftYear/TeamDraftYear";


function Team() {
    const [error, setError] = useState(null);
    const [draftHistory, setDraftHistory] = useState([]);
    const [team, setTeam] = useState(null);
    const [draftPicks, setDraftPicks] = useState(null);
    const [draftGrades, setDraftGrades] = useState(null);

    const { team_id } = useParams();

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "/teams/" + team_id).then((response) => {
            setTeam(response.data);
        })
            .catch((error) => {
                setTeam(null);
                setError(error);
            });
    }, [team_id]);


    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "/draft_grades/team", { params: { "team_id": team_id } }).then((response) => {
            setDraftGrades(response.data);
        })
            .catch((error) => {
                setDraftGrades(null);
                setError(error);
            });
    }, [team_id]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "/draft/history/" + team_id).then((response) => {
            setDraftHistory(response.data);
        })
            .catch((error) => {
                setDraftHistory([]);
                setError(error);
            });
    }, [team_id]);


    function formatTeamIdToName(team_id) {
        var name = team_id.split("_").map((word) => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        }).join(" ");
        return name;
    }

    useEffect(() => {
        document.title = formatTeamIdToName(team_id) + " - Hockey Pipeline";
    }, [team_id]);

    function getGradeByYear(draft_year){
        if (draft_year != null && draftGrades != null){
            for (var i = 0; i < draftGrades.length; i++) {
                if (draftGrades[i].draft_year == draft_year) {
                    return draftGrades[i].grade;
                }
            }
        }
        return null;
    }

    return (
        <>
            <Container className="top-container">
                <Grid container rowSpacing={{ xs: 2, sm: 2, md: 3 }}>
                    {team && <Grid item xs={12} sm={12} md={12} >
                        <div className="container team-page-header">
                            <div className="team-page-header-title">
                                <img src={team.team_logo_url} className="team-logo" ></img>
                                <Typography variant="body1" className="team-name">
                                    {team.team_name}
                                </Typography>
                            </div>
                        </div>
                    </Grid>}

                    {draftHistory && draftGrades && draftHistory.map((draft_year) => (
                        <TeamDraftYear draft_year={draft_year} draft_grade={getGradeByYear(draft_year.draft_year)} />
                    ))}
                </Grid>
            </Container >
        </>
    )
}

export default Team;