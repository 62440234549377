const goalieColumns = [
    {
        accessorKey: 'team.league.league_name',
        header: 'League',
        size: 20, //small column
    },
    {
        accessorKey: 'team.team_name',
        header: 'Team',
        size: 20, //small column
    },
    {
        accessorKey: 'year',
        header: 'Year',
        size: 20, //small column
        Cell: ({ cell, row }) => {return ((row.original.is_playoff))? row.original.year + " Playoffs" : row.original.year;}
    },
    {
        accessorKey: 'games_played',
        header: 'Games Played',
        size: 50, //small column
    },
    {
        accessorKey: 'record',
        header: 'Record',
        size: 50, //small column
    },
    {
        accessorKey: 'goals_against_average',
        header: 'GAA',
        size: 50, //small column
    },
    {
        accessorKey: 'save_percentage',
        header: 'Save %',
        size: 50, //small column
    },
    {
        accessorKey: 'shutouts',
        header: 'Shutouts',
        size: 50, //small column
    },
    {
        accessorKey: 'penalty_minutes',
        header: 'PIMs',
        size: 50, //small column

    },
]

const playerColumns = [
    {
        accessorKey: 'team.league.league_name',
        header: 'League',
        size: 20, //small column
    },
    {
        accessorKey: 'team.team_name',
        header: 'Team',
        size: 20, //small column
    },
    {
        accessorKey: 'year',
        header: 'Year',
        size: 20, //small column
        Cell: ({ cell, row }) => {return ((row.original.is_playoff))? row.original.year + " Playoffs" : row.original.year;}
    },
    {
        accessorKey: 'games_played',
        header: 'Games Played',
        size: 50, //small column
    },
    {
        accessorKey: 'goals',
        header: 'Goals',
        size: 50, //small column
    },
    {
        accessorKey: 'assists',
        header: 'Assists',
        size: 50, //small column
    },
    {
        header: 'Points',
        size: 50, //small column
        Cell: ({ cell, row }) => {return (row.original.goals + row.original.assists)}

    },
    {
        header: 'PPG',
        size: 50, //small column
        Cell: ({ cell, row }) => {return parseFloat((row.original.goals + row.original.assists)/row.original.games_played).toFixed(2);}
    },
    {
        accessorKey: 'penalty_minutes',
        header: 'PIMs',
        size: 50, //small column

    },

]

export { goalieColumns, playerColumns };