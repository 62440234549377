import React, { useState, useEffect, useContext } from "react";
import { Typography, Grid, Container } from '@mui/material';
import axios from "axios";
import { DraftYearContext } from "../../contexts/DraftYearContext";
import { Link, useNavigate } from "react-router-dom";
import PageLoader from "../../components/PageLoader/PageLoader";


function DraftOrder() {
    const [draftPicks, setDraftPicks] = useState([]);
    const [draftCompleted, setDraftCompleted] = useState(false);    
    const [error, setError] = useState(null);
    const { draftYear, setDraftYear } = useContext(DraftYearContext);

    // Loading States
    const [loading, setLoading] = useState(true);
    const [draftYearChange, setDraftYearChange] = useState(true);
    const [prevDraftYear, setPrevDraftYear] = useState(null);

    useEffect(() => {
        setLoading(true);
        axios.get(process.env.REACT_APP_API_URL + "/draft/picks", { params: { "draft_year": draftYear } }).then((response) => {
            let picks = {};
            response.data.forEach((pick) => {
                if (pick.player_id) {
                    setDraftCompleted(true);
                }
                if (!picks[pick.round]) {
                    picks[pick.round] = [pick];
                } else {
                    picks[pick.round].push(pick);
                }
            })
            setDraftPicks(picks);
            setLoading(false);
        })
            .catch((error) => {
                setError(error);
            });
    }, [draftYear]);

    useEffect(() => {
        document.title = 'Draft Order - Hockey Pipeline';
    }, []);

    useEffect(() => {
        if (prevDraftYear == null) {
            setPrevDraftYear(draftYear);
        }
        else {
            if (prevDraftYear != draftYear) {
                setDraftYearChange(false);
            }
        }
        setPrevDraftYear(draftYear);
    }, [draftYear]);

    function getIsLoading() {
        return loading && !draftYearChange;
    }


    return (
        <>
            <PageLoader loading={getIsLoading()} />
            <Container className="container top-container">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        {draftCompleted && <Typography variant="h4" component="div" className="page-title">
                            Draft Results
                        </Typography>}
                        {!draftCompleted && <Typography variant="h4" component="div" className="page-title">
                            Draft Order
                        </Typography>}
                        <div className="title-underline" />
                    </Grid>

                    {draftPicks && Object.entries(draftPicks).map(([round, picks]) => (
                        <Grid container >
                            <Grid item xs={12} sm={12} md={12} >
                                <Typography variant="h4" component="div" className="round-number">
                                    Round {round}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} >
                                {picks.slice(0, picks.length / 2).map((pick) => (
                                    <Grid item xs={12} sm={12} md={12} className="draft-card">
                                        <Typography variant="body1" className="draft-card-number">
                                            {pick.pick}.
                                        </Typography>
                                        <Typography variant="body1" >
                                            {pick.player_id &&
                                                <Link to={"/player/" + pick.player_id} className="inline-draft-text">
                                                    <img src={(pick.drafting_team_logo == null ) ? pick.original_team_logo : pick.drafting_team_logo} className="team-logo" ></img>
                                                    <div className="draft-card-text">{pick.first_name} {pick.last_name}</div>
                                                </Link>}

                                            {!pick.drafting_team_name && !pick.player_id &&
                                                <Link to={"/team/" + pick.original_team_id} className="inline-draft-text">
                                                    <img src={pick.original_team_logo} className="team-logo" ></img>
                                                    <div className="draft-card-text">{pick.original_team_name}</div>
                                                </Link>}

                                            {pick.drafting_team_name && !pick.player_id &&
                                                <Link to={"/team/" + pick.drafting_team_id} className="inline-draft-text">
                                                    <img src={pick.drafting_team_logo} className="team-logo"></img>
                                                    <div className="draft-card-text"> {pick.drafting_team_name} </div>
                                                    <Link to={"/team/" + pick.original_team_id} className="via-draft-long">via: {pick.original_team_name} </Link>
                                                </Link>}
                                        </Typography>
                                    </Grid>))}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} >
                                {picks.slice((picks.length / 2),).map((pick) => (
                                    <Grid item xs={12} sm={12} md={12} className="draft-card">
                                        <Typography variant="body1" className="draft-card-number">
                                            {pick.pick}.
                                        </Typography>
                                        <Typography variant="body1" >
                                            {pick.player_id &&
                                                <Link to={"/player/" + pick.player_id} className="inline-draft-text">
                                                    <img src={(pick.drafting_team_logo == null ) ? pick.original_team_logo : pick.drafting_team_logo} className="team-logo" ></img>
                                                    <div className="draft-card-text">{pick.first_name} {pick.last_name}</div>
                                                </Link>}

                                            {!pick.drafting_team_name && !pick.player_id &&
                                                <Link to={"/team/" + pick.original_team_id} className="inline-draft-text">
                                                    <img src={pick.original_team_logo} className="team-logo" ></img>
                                                    <div className="draft-card-text">{pick.original_team_name}</div>
                                                </Link>}

                                            {pick.drafting_team_name && !pick.player_id &&
                                                <Link to={"/team/" + pick.drafting_team_id} className="inline-draft-text">
                                                    <img src={pick.drafting_team_logo} className="team-logo"></img>
                                                    <div className="draft-card-text"> {pick.drafting_team_name} </div>
                                                    <Link to={"/team/" + pick.original_team_id} className="via-draft-long">via: {pick.original_team_name} </Link>
                                                </Link>}
                                        </Typography>
                                    </Grid>))}
                            </Grid>
                        </Grid>

                    ))}
                </Grid>
            </Container>
        </>
    );
};

export default DraftOrder;