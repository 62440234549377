import React, { useState, useEffect, useContext } from "react";
import { Typography, Grid, Card, CardHeader, CardContent } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Link, useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { format, formatDistance, formatRelative, subDays } from 'date-fns'
import { DraftYearContext } from "../../contexts/DraftYearContext";
import PageLoader from "../../components/PageLoader/PageLoader";



import "./Home.css"


import axios from "axios";
import PlayerCard from "../../components/PlayerCard/PlayerCard";
import { Container } from "@mui/system";

function Home() {
  const [players, setPlayers] = useState([]);
  const [trendingPlayers, setTrendingPlayers] = useState([]);
  const [countryPlayers, setCountryPlayers] = useState([]);
  const [draftPicks, setDraftPicks] = useState(null);
  const [draftCompleted, setDraftCompleted] = useState(false);    
  const [topPlayers, setTopsPlayers] = useState(null);
  const [rankMocks, setRankMocks] = useState(null);
  const [error, setError] = useState(null);
  const [country, setCountry] = useState('');
  const { draftYear, setDraftYear } = useContext(DraftYearContext);


  // Loading States
  const [loadingTopProspects, setLoadingTopProspects] = useState(true);
  const [loadingDraftResults, setLoadingDraftResults] = useState(true);
  const [loadingLatest, setLoadingLatest] = useState(true);
  const [loadingTrending, setLoadingTrending] = useState(true);
  const [draftYearChange, setDraftYearChange] = useState(true);
  const [prevDraftYear, setPrevDraftYear] = useState(null);
  
  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await axios.get('https://api.seeip.org/geoip');
        const { country } = response.data;
        setCountry(country);
        setCountryPlayers(players.filter((player) => player.country_id === country.toLowerCase().replace(/\s/g, '_')).slice(0, 6));
      } catch (error) {
        console.error('Error fetching country:', error);
      }
    };
    fetchCountry();
  }, [players]);

  useEffect(() => {
    document.title = 'Home - Hockey Pipeline';
  }, []);

  useEffect(() => {
    setLoadingTopProspects(true);
    axios.get(process.env.REACT_APP_API_URL + "/players", { params: { "draft_year": draftYear } } ).then((response) => {
      setPlayers(response.data)
      setTopsPlayers(response.data.slice(0, 6));
      setLoadingTopProspects(false);
    })
      .catch((error) => {
        setError(error);
      });
  }, [draftYear]);

  useEffect(() => {
    setLoadingDraftResults(true);
    axios.get(process.env.REACT_APP_API_URL + "/draft/picks", { params: { "count": 10, "draft_year": draftYear } }).then((response) => {
      setDraftPicks(response.data);
      setDraftCompleted(response.data[0].player_id != null);
      setLoadingDraftResults(false);
    })
      .catch((error) => {
        setError(error);
      });
  }, [draftYear]);

  useEffect(() => {
    setLoadingLatest(true);
    axios.get(process.env.REACT_APP_API_URL + "/shared/get_mocks_and_rankings", { params: { "count": 7, "draft_year": draftYear } }).then((response) => {
      setRankMocks(response.data);
      setLoadingLatest(false);
    })
      .catch((error) => {
        setError(error);
      });
  }, [draftYear]);

  useEffect(() => {
    setLoadingTrending(true);
    axios.get(process.env.REACT_APP_API_URL + "/trending_consensus_rankings", { params: { "count": 10, 'min_rank_start': 60, 'months': '3', "draft_year": draftYear } }).then((response) => {
      setTrendingPlayers(response.data);
      setLoadingTrending(false);
    })
      .catch((error) => {
        setError(error);
      });
  }, [draftYear]);

  useEffect(() => {
    if (prevDraftYear == null) {
      setPrevDraftYear(draftYear);
    }
    else {
      if (prevDraftYear != draftYear) {
        setDraftYearChange(false);
      }
    }
    setPrevDraftYear(draftYear);
  }, [draftYear]);


  function getIsLoading() {
    return !(!loadingTopProspects && !loadingDraftResults && !loadingLatest && !loadingTrending) && !draftYearChange;
  }

  return (
    <>
      <PageLoader loading={getIsLoading()}/>
      {/* TOP 6 Players*/}
      <Container className="container top-container" >
        
        <Grid container columnSpacing={4} spacing={2}>
          <Grid item xs={12} sm={12} md={12} className="top-container">
            <Typography variant="h4" component="div" className="mini-title">
              Top 6 Prospects
            </Typography>
          </Grid>
          {topPlayers && topPlayers.map((player) => (
            <Grid item xs={6} sm={6} md={2}>
              <PlayerCard playerData={player} shortView={true} />
            </Grid>
          ))}
          <Grid item xs={12} sm={12} md={12}>
            <Link to="/consensus-rankings" className="view-more-link">
              <Typography variant="body1">
                View All
                <ArrowForwardIosIcon />
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </Container>

      <Container className="top-container" >
        <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} rowSpacing={{ xs: 2, sm: 2, md: 3 }}>
          {/* Draft Order */}
          <Grid item xs={12} sm={12} md={4} >
            <div className="container">
            {draftCompleted && <Typography variant="h4" component="div" className="mini-title">
                Draft Results
              </Typography>}
              {!draftCompleted && <Typography variant="h4" component="div" className="mini-title">
                Draft Order
              </Typography>}
              {draftPicks && draftPicks.map((pick) => (
                <Grid item xs={12} sm={12} md={12} className="draft-card">
                  <Typography variant="body1" className="draft-card-number">
                    {pick.pick}.
                  </Typography>
                  <Typography variant="body1" >
                  {draftCompleted &&
                      <Link to={"/player/" + pick.player_id} className="inline-draft-text">
                        <img src={pick.original_team_logo} className="team-logo" ></img>
                        <div className="draft-card-text">{pick.first_name} {pick.last_name}</div>
                      </Link>}


                    {!draftCompleted && !pick.drafting_team_name &&
                      <Link to={"/team/" + pick.original_team_id} className="inline-draft-text">
                        <img src={pick.original_team_logo} className="team-logo" ></img>
                        <div className="draft-card-text">{pick.original_team_name}</div>
                      </Link>}
                    {!draftCompleted && pick.drafting_team_name &&
                      <Link to={"/team/" + pick.drafting_team_id} className="inline-draft-text">
                        <img src={pick.drafting_team_logo} className="team-logo"></img>
                        <div className="draft-card-text"> {pick.drafting_team_name} </div>
                        <Link to={"/team/" + pick.original_team_id} className="via-draft">via: {pick.original_team_name} </Link>
                      </Link>}
                  </Typography>
                </Grid>
              ))}
              <Grid item xs={12} sm={12} md={12}>
                <Link to="/draft-order" className="view-more-link">
                  <Typography variant="body1">
                    View All
                    <ArrowForwardIosIcon />
                  </Typography>
                </Link>
              </Grid>
            </div>
          </Grid>

          {/* Latest Mocks and Rankings */}
          <Grid item xs={12} sm={6} md={4}>
            <div className="container">
              <Typography variant="h4" component="div" className="mini-title">
                Latest Mock Drafts & Rankings
              </Typography>
              {rankMocks && rankMocks.map((rankMock) => (
                <Grid item xs={12} sm={12} md={12} className="rank-mock-mini">
                  <Link to={((rankMock.rank_type === "mock_draft") ? "../mock-draft/" : "../ranking/") + rankMock.data_point_id}>
                    <Typography variant="body1" className="rank-mock-title">
                      {rankMock.title}
                    </Typography>

                    <Typography variant="body1" className="rank-mock-subtitle">
                      {(rankMock.rank_type == "ranking") ? "Ranking" : "Mock Draft"}
                      <div className="rank-mock-divider"></div>
                      {formatDistance(subDays(new Date(rankMock.creation_date), 3), new Date(), { addSuffix: true })}
                    </Typography>
                  </Link>
                </Grid>
              ))}

              <Grid item xs={12} sm={12} md={12}>
                <Link to="/mocks-and-rankings" className="view-more-link">
                  <Typography variant="body1">
                    View All
                    <ArrowForwardIosIcon />
                  </Typography>
                </Link>
              </Grid>
            </div>
          </Grid>

          {/* Trending Players */}
          <Grid item xs={12} sm={6} md={4}>
            <div className="container">
              <Typography variant="h4" component="div" className="mini-title">
                Trending Players
              </Typography>

              <Typography variant="h4" component="div" className="trending-players-sub-heading">
              </Typography>

              {trendingPlayers && trendingPlayers.map((player) => (
                <Grid item xs={12} sm={12} md={12} className="trending-player-card">
                  <Grid item xs={2} sm={2} md={2} >
                    <Typography variant="body1" className="trending-player-rank">
                      {player.delta > 10 && <div className="rank-increase"><KeyboardDoubleArrowUpIcon /> {player.delta}</div>}
                      {player.delta <= 10 && player.delta > 0 && <div className="rank-increase"><KeyboardArrowUpIcon /> {player.delta}</div>}

                      {player.delta < 0 && player.delta >= -10 && <div className="rank-drop"><KeyboardArrowDownIcon /> {player.delta}</div>}
                      {player.delta < -10 && <div className="rank-drop"><KeyboardDoubleArrowDownIcon /> {player.delta}</div>}
                    </Typography>
                  </Grid>
                  <Grid item xs={10} sm={10} md={10} >
                    <Link to={"/player/" + player.player_id}>
                      <Typography variant="body1" className="trending-player-name">
                        {player.player_name}
                      </Typography>
                    </Link>
                  </Grid>

                </Grid>

              ))}
              <Grid item xs={12} sm={12} md={12}>
                <Link to="/players" className="view-more-link">
                  <Typography variant="body1">
                    View All
                    <ArrowForwardIosIcon />
                  </Typography>
                </Link>
              </Grid>
            </div>

          </Grid>
        </Grid>
      </Container>

      {/* TOP 6 Players*/}
      <Container className="container top-container" >
        <Grid container columnSpacing={4} spacing={2}>
          <Grid item xs={12} sm={12} md={12} className="top-container">
            <Typography variant="h4" component="div" className="mini-title">
              Top Prospects from {country}
            </Typography>
          </Grid>
          {countryPlayers && countryPlayers.map((player) => (
            <Grid item xs={6} sm={6} md={2}>
              <PlayerCard playerData={player} shortView={true} />
            </Grid>
          ))}
          <Grid item xs={12} sm={12} md={12}>
            <Link to="/players" className="view-more-link">
              <Typography variant="body1">
                View All
                <ArrowForwardIosIcon />
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </Container>

    </>
  );
}

export default Home;
