import React, { useState, useEffect, useMemo } from "react";
import { Typography, Grid } from '@mui/material';
import { useParams } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import PlayerRankMockCard from "../../components/PlayerRankMockCard/PlayerRankMockCard";
import MaterialReactTable from 'material-react-table';
import moment from 'moment';
import PlayerDraftOdd from "../../components/PlayerDraftOdd/PlayerDraftOdd";
import axios from "axios";
import PlayerCard from "../../components/PlayerCard/PlayerCard";
import PlayerGraph from "../../components/PlayerGraph/PlayerGraph";
import { Container } from "@mui/system";
import { goalieColumns, playerColumns } from "./Constants.js";

import "./Player.css"

function Player() {
    const [playerData, setPlayerData] = useState(null);
    const [playerStats, setPlayerStats] = useState(null);

    const [graphData, setGraphData] = useState(null);
    const [lastConsensusData, setLastConsensusData] = useState(null);
    const [playerRankMocks, setPlayerRankMocks] = useState(null);
    const [playerDraftProbabilities, setPlayerDraftProbabilities] = useState([]);
    const [playerReel, setPlayerReel] = useState([]);
    const [error, setError] = useState(null);
    const { player_id } = useParams();

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "/players/" + player_id).then((response) => {
            setPlayerData(response.data);
            document.title = response.data.first_name + " " + response.data.last_name + " - Hockey Pipeline"
        })
            .catch((error) => {
                setPlayerData([]);
                setError(error);
            });
    }, [player_id]);


    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "/player_stats/" + player_id).then((response) => {
            setPlayerStats(response.data);
        })
            .catch((error) => {
                setPlayerStats([]);
                setError(error);
            });
    }, [player_id]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "/player_draft_probabilities/player/" + player_id).then((response) => {
            setPlayerDraftProbabilities(response.data);
        })
            .catch((error) => {
                setPlayerDraftProbabilities([]);
                setError(error);
            });
    }, [player_id]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "/player_reel/" + player_id).then((response) => {
            setPlayerReel(response.data);
        })
            .catch((error) => {
                setPlayerReel(null);
                setError(error);
            });
    }, [player_id]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "/shared/get_player_mocks_and_rankings/" + player_id).then((response) => {
            setPlayerRankMocks(response.data);
        })
            .catch((error) => {
                setPlayerRankMocks([]);
                setError(error);
            });
    }, [player_id]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "/consensus_rankings/" + player_id).then((response) => {
            setLastConsensusData(response.data[response.data.length - 1]);
            return response.data
        }).then((re) => {
            const scores = re.map(item => Math.round(item.score * 1e2) / 1e2);
            const effectiveDates = re.map(item => moment(item.effective_date).valueOf());
            const lastRank = re.map(item => ((item.has_data_point) ? item.last_rank : null));
            const consensusRanking = re.map(item => item.rank);
            const lastRankType = re.map(item => item.last_rank_type);

            // Create an array of objects combining the effective date, score, and rank
            setGraphData(re.map((item, index) => ({
                effective_date: effectiveDates[index],
                score: scores[index],
                lastRank: lastRank[index],
                consensusRanking: consensusRanking[index],
                lastRankType: lastRankType[index]
            })));
        })
            .catch((error) => {
                setError(error);
            });
    }, [player_id]);

    let darkTheme = createTheme({
        palette: {
            mode: 'dark', // Switching the dark mode on is a single property value change.

            info: {
                main: '#678651', //add in a custom color for the toolbar alert background stuff
                secondary: '#678651',
            },
            background: {
                default: 'rgb(37, 37, 38, 0.2)', //pure black table in dark mode for fun
            },
        },
        shadows: ["none"]
    });

    const playerColumnsMemo = useMemo(
        () => playerColumns, [],
    );

    const goalieColumnsMemo = useMemo(
        () => goalieColumns, [],
    );


    return (
        <div>

            {/* PLAYER CARD AND GRAPH */}
            <Container style={{ marginTop: 40 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={3}>
                        {playerData && <PlayerCard playerData={playerData} />}
                    </Grid>
                    {lastConsensusData && <Grid item xs={12} sm={12} md={9} spacing={4}>
                        <div className="container">
                            <Grid item xs={12} sm={12} md={12} >
                                {graphData && <PlayerGraph graphData={graphData} />}
                            </Grid>
                            <Container >
                                <Grid container spacing={2}>
                                    <Grid item xs={6} sm={6} md={12 / 5} >
                                        <div className="rank-stat">
                                            <Typography variant="h4" component="div" className="rank-stat-title">
                                                Number of Rankings:
                                            </Typography>
                                            <Typography variant="h4" component="div" className="rank-stat-data">
                                                {lastConsensusData.count}
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={12 / 5}>
                                        <div className="rank-stat">
                                            <Typography variant="h4" component="div" className="rank-stat-title">
                                                Highest Ranking:
                                            </Typography>
                                            <Typography variant="h4" component="div" className="rank-stat-data">
                                                {lastConsensusData.best_rank}
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={12 / 5}>
                                        <div className="rank-stat">
                                            <Typography variant="h4" component="div" className="rank-stat-title">
                                                Lowest Ranking:
                                            </Typography>
                                            <Typography variant="h4" component="div" className="rank-stat-data">
                                                {lastConsensusData.worst_rank}
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={12 / 5}>
                                        <div className="rank-stat">
                                            <Typography variant="h4" component="div" className="rank-stat-title">
                                                Last Ranking:
                                            </Typography>
                                            <Typography variant="h4" component="div" className="rank-stat-data">
                                                {lastConsensusData.last_rank}
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={12 / 5}>
                                        <div className="rank-stat">
                                            <Typography variant="h4" component="div" className="rank-stat-title">
                                                Standard Deviation:
                                            </Typography>
                                            <Typography variant="h4" component="div" className="rank-stat-data">
                                                {Math.round(lastConsensusData.std_dev * 1e2) / 1e2}
                                            </Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Container>
                        </div>
                    </Grid>}

                </Grid>
            </Container>


            {/* Preview */}
            {playerReel && <Container >
                <Grid container columnSpacing={2} style={{ marginTop: 20 }}>
                    {playerReel && <Grid item xs={12} sm={12} md={6} >
                        <div className="container con-no-bot-pad">
                            <div className="embeded-video">
                                <iframe width="100%" height="325px" src={playerReel.reel_url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                        </div>
                    </Grid>}
                    {playerDraftProbabilities && <Grid item xs={12} sm={12} md={6}>
                        <div className="container con-no-bot-pad player-draft-odds">
                            <div className="draft-odds">
                                <Typography variant="h4" component="div" className="draft-odds-title">
                                    Draft Odds
                                </Typography>
                                <Grid container columnSpacing={0}>
                                    <Grid item xs={12} sm={12} md={6}>
                                        {playerDraftProbabilities && playerDraftProbabilities.slice(0, playerDraftProbabilities.length / 2)
                                            .map((draftProb) => (
                                                <PlayerDraftOdd draftProb={draftProb} />
                                            ))
                                        }
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        {playerDraftProbabilities && playerDraftProbabilities.slice(playerDraftProbabilities.length / 2, playerDraftProbabilities.length)
                                            .map((draftProb) => (
                                                <PlayerDraftOdd draftProb={draftProb} />
                                            ))
                                        }
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Grid>}
                </Grid>
            </Container>}

            {!playerReel && playerDraftProbabilities && playerDraftProbabilities.length > 0 && <Container >
                <Grid container columnSpacing={2} style={{ marginTop: 20 }}>
                    {playerDraftProbabilities && <Grid item xs={12} sm={12} md={12}>
                        <div className="container con-no-bot-pad player-draft-odds">
                            <div className="draft-odds">
                                <Typography variant="h4" component="div" className="draft-odds-title">
                                    Draft Odds
                                </Typography>
                                <Grid container columnSpacing={0}>
                                    <Grid item xs={12} sm={12} md={4}>
                                        {playerDraftProbabilities && playerDraftProbabilities.slice(0, playerDraftProbabilities.length / 3)
                                            .map((draftProb) => (
                                                <PlayerDraftOdd draftProb={draftProb} />
                                            ))
                                        }
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4}>
                                        {playerDraftProbabilities && playerDraftProbabilities.slice(playerDraftProbabilities.length / 3, (playerDraftProbabilities.length / 3) * 2)
                                            .map((draftProb) => (
                                                <PlayerDraftOdd draftProb={draftProb} />
                                            ))
                                        }
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4}>
                                        {playerDraftProbabilities && playerDraftProbabilities.slice((playerDraftProbabilities.length / 3) * 2, playerDraftProbabilities.length)
                                            .map((draftProb) => (
                                                <PlayerDraftOdd draftProb={draftProb} />
                                            ))
                                        }
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Grid>}
                </Grid>
            </Container>}


            {/* PLAYER STATS */}
            <Container style={{ marginTop: 40 }} className="container" >
                <Grid container style={{ position: "relative", paddingTop: "15px", display: "block" }}>
                    <Typography variant="h4" component="div" className="inner-table-title">
                        Draft Year Stats
                    </Typography>
                    {playerData && playerStats && <Grid item xs={12} sm={12} md={12}>
                        <ThemeProvider theme={darkTheme}>
                            <MaterialReactTable
                                columns={(playerData.position == "G") ? goalieColumnsMemo : playerColumnsMemo}
                                data={playerStats}
                                initialState={{ density: 'compact', pagination: { pageSize: 10 } }}
                            />
                        </ThemeProvider>
                    </Grid>}
                </Grid>
            </Container>


            {/* PLAYER RANKINGS */}
            <Container spacing={2} style={{ marginTop: 40 }} className="container" >
                <Grid container >
                    <Grid item xs={12} sm={12} md={12} >
                        <Typography variant="h4" component="div" className="page-title">
                            All Rankings
                        </Typography>
                        <div className="title-underline" />
                    </Grid>
                    {playerRankMocks && playerRankMocks.map((playerRankMock) => (
                        <Grid item xs={12} sm={12} md={12} >
                            <PlayerRankMockCard playerRankMock={playerRankMock} />
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </div>
    );
};

export default Player;
