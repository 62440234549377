import React, { useState, useEffect, useContext } from "react";
import { Typography, Grid, Select, MenuItem, Button, InputLabel, FormControl } from '@mui/material';
import { Container } from "@mui/system";
import { useSearchParams } from "react-router-dom";
import { DraftYearContext } from "../../contexts/DraftYearContext";
import axios from "axios";
import RankMockCard from "../../components/RankMockCard/RankMockCard";
import "./MocksAndRankings.css"
import PageLoader from "../../components/PageLoader/PageLoader";


function MocksAndRankings() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [rankMocks, setRankMocks] = useState(null);
    const [scout, setScout] = useState(searchParams.get('scouts_id'));
    const [scouts, setScouts] = useState([]);
    const { draftYear, setDraftYear } = useContext(DraftYearContext);
    const [publication, setPublication] = useState(searchParams.get('publication_id'));
    const [publications, setPublications] = useState([]);
    const [error, setError] = useState(null);

    // Loading States
    const [loading, setLoading] = useState(true);
    const [draftYearChange, setDraftYearChange] = useState(true);
    const [prevDraftYear, setPrevDraftYear] = useState(null);

    useEffect(() => {
        setLoading(true);
        axios.get(process.env.REACT_APP_API_URL + "/shared/get_mocks_and_rankings", { params: { "scouts_id": scout, "publication_id": publication, "draft_year": draftYear } }).then((response) => {
            setRankMocks(response.data);
            setLoading(false);
        })
            .catch((error) => {
                setError(error);
            });

    }, [scout, publication, draftYear]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "/scouts").then((response) => {
            setScouts(response.data);
        })
            .catch((error) => {
                setError(error);
            });
    }, []);


    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "/publications").then((response) => {
            setPublications(response.data);
        })
            .catch((error) => {
                setError(error);
            });
    }, []);

    useEffect(() => {
        if (searchParams.get('publication_id')) {
            setPublication(searchParams.get('publication_id'));
        };
        if (searchParams.get('scouts_id')) {
            setScout(searchParams.get('scouts_id'));
        }
    }, [publication, scout]);

    const handleScoutChange = (event) => {
        setScout(event.target.value);
        setSearchParams({ scouts_id: event.target.value, publication_id: publication });
    };
    const handlePublicationChange = (event) => {
        setPublication(event.target.value);
        setSearchParams({ scouts_id: scout, publication_id: event.target.value });
    };

    const clearFilters = () => {
        setScout("");
        setPublication("");
        setSearchParams();
    };

    useEffect(() => {
        document.title = 'Mocks and Drafts - Hockey Pipeline';
    }, []);

    useEffect(() => {
        if (prevDraftYear == null) {
            setPrevDraftYear(draftYear);
        }
        else {
            if (prevDraftYear != draftYear) {
                setDraftYearChange(false);
            }
        }
        setPrevDraftYear(draftYear);
    }, [draftYear]);

    function getIsLoading() {
        return loading && !draftYearChange;
    }

    return (
        <>
            <PageLoader loading={getIsLoading()} />
            <Container Container className="container top-container">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} width="100%">
                        <Typography variant="h4" component="div" className="page-title">
                            Mock Drafts and Rankings
                        </Typography>
                        <div className="title-underline" />
                        <Grid container spacing={2} justifyContent="right">
                            <Grid item xs={12} sm={4} md={3} direction="row">
                                <InputLabel className="drop-down-label">Scout</InputLabel>
                                <Select
                                    value={scout}
                                    autoWidth
                                    label="Scout"
                                    labelId="demo-simple-select-autowidth-label"
                                    id="demo-simple-select-autowidth"
                                    className="drop-down"
                                    onChange={handleScoutChange}
                                    size="small"
                                >
                                    {scouts.map((scout) => (
                                        <MenuItem key={scout.scouts_id} value={scout.scouts_id}>
                                            {scout.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <InputLabel className="drop-down-label">Publication</InputLabel>
                                <Select
                                    size="small"
                                    value={publication}
                                    autoWidth
                                    label="Scout"
                                    className="drop-down"
                                    onChange={handlePublicationChange}
                                >
                                    {publications.map((publication) => (
                                        <MenuItem key={publication.publication_id} value={publication.publication_id}>
                                            {publication.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item xs={12} sm={4} md={2} className="clear-btn-container" >
                                <Button
                                    variant="outlined"
                                    className="clear-btn"
                                    onClick={clearFilters}
                                >
                                    Clear Filter
                                </Button>
                            </Grid>
                        </Grid>
                        {rankMocks && rankMocks.map((rankMock) => (
                            <Grid item xs={12} sm={12} md={12}>
                                <RankMockCard rankMock={rankMock} />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default MocksAndRankings;