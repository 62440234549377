import React from "react";
import { Typography, Card, CardMedia, Grid } from '@mui/material';
import { format } from "date-fns";
import { Link } from 'react-router-dom';
import { nhlTeamColors } from "./Constants.js"

import "./PlayerCard.css"

function pickAndRoundToOverall(pick, round, year) {
    var team_count = 30
    if (year >= 2021) {
        team_count = 32
    }
    else if (year >= 2017) {
        team_count = 31
    }
    return pick + (round - 1) * team_count
}

function PlayerCard({ playerData, ranking, odds, nhlTeam, shortView, miniView, glow }) {
    return (
        <Card className={"player-card " + (glow ? "glow" : "")} >
            <Link to={"../player/" + playerData.player_id}>
                <div className="card-wrapper" >
                    {!ranking && !odds && <div className="card-ranking">
                        <Typography variant="body2" component="h2" className="rank-title" >
                            RANK:
                        </Typography>
                        <Typography variant="h5" component="h2" style={{ fontWeight: 1000 }} className="rank-value">
                            {((playerData.consensus_ranking) ? playerData.consensus_ranking : "N/A")}
                        </Typography>
                    </div>}
                    {ranking && !odds && <div className="card-ranking">
                        <Typography variant="body2" component="h2" className="rank-title" >
                            {(nhlTeam) || (playerData.pick) ? "PICK:" : "RANK:"}
                        </Typography>
                        <Typography variant="h5" component="h2" style={{ fontWeight: 1000 }} className="rank-value">
                            {ranking}
                        </Typography>
                    </div>}

                    {odds && <div className="card-ranking">
                        <Typography variant="body2" component="h2" className="rank-title" >
                            ODDS:
                        </Typography>
                        <Typography variant="h5" component="h2" style={{ fontWeight: 1000 }} className="odds-value">
                            {(odds * 100).toFixed(1)}%
                        </Typography>
                    </div>}

                    {!playerData.player_image_url && <CardMedia image=" https://awoiaf.westeros.org/images/thumb/5/56/No_portrait.svg/240px-No_portrait.svg.png" component="img" className="default-image" />}
                    {playerData.player_image_url && <CardMedia image={playerData.player_image_url} component="img" />}
                    <div className="overlay" />
                    <Grid container columnSpacing={0} style={{ position: "absolute", bottom: 0 }}>
                        {playerData.country && playerData.country.flag_url && <img src={playerData.country.flag_url} style={{ width: "20%", marginBottom: "32px", marginLeft: "10px" }} />}
                        {playerData.flag_url && <img src={playerData.flag_url} style={{ width: "20%", marginBottom: "32px", marginLeft: "10px" }} />}

                        <Grid item xs={12} sm={12} md={12} className="name-box">
                            <Typography variant="h4" component="h2" className="first-name">
                                {playerData.first_name}
                            </Typography>
                            <Typography variant="h3" component="h2" className="last-name" style={{ fontWeight: 1000 }}>
                                {playerData.last_name}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
                {shortView && <div className="short-view-spacer" />}
                {miniView && <Grid container padding={2} >
                    <Grid item xs={12} sm={12} md={12} className="miniview-data">
                        <Typography variant="body1" component="h2" className="miniview-pos">
                            Pos: <b>{playerData.position}</b>
                        </Typography>
                        <Typography variant="body1" component="h2" className="miniview-dob">
                            D.O.B <b>{format(new Date(playerData.dob), "dd-MM-yyyy")}</b>
                        </Typography>
                    </Grid>
                </Grid>}
                <div>
                    {!shortView && !miniView && <Grid container columnSpacing={2} rowSpacing={1} padding={2} className="card-data">
                        <Grid item xs={6} sm={6} md={4}>
                            <Typography variant="body1" component="h2" className="stat-title">
                                Position:
                            </Typography>
                            <Typography variant="h5" component="h2" className="stat-value">
                                {playerData.position}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={8}>
                            <Typography variant="body1" component="h2" className="stat-title">
                                DOB:
                            </Typography>
                            <Typography variant="body1" component="h2" className="stat-value" >
                                {format(new Date(playerData.dob), "MMM do, yyyy")}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={4}>
                            <Typography variant="body1" component="h2" className="stat-title">
                                Shoots:
                            </Typography>
                            <Typography variant="h5" component="h2" className="stat-value">
                                {((playerData.handedness == "L") ? "Left" : "Right")}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={4}>
                            <Typography variant="body1" component="h2" className="stat-title">
                                Height:
                            </Typography>
                            <Typography variant="h5" component="h2" className="stat-value">
                                {playerData.height}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={4}>
                            <Typography variant="body1" component="h2" className="stat-title">
                                Weight:
                            </Typography>
                            <Typography variant="h5" component="h2" className="stat-value">
                                {playerData.weight}
                                <div style={{ display: "inline-block", marginLeft: 5, fontSize: 16 }}>lbs</div>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography variant="body1" component="h2" className="stat-title">
                                Birthplace:
                            </Typography>
                            <Typography variant="body2" component="h2" className="stat-value birthplace-value">
                                {playerData.birthplace}

                            </Typography>
                        </Grid>
                    </Grid>}
                    {nhlTeam && <Grid item xs={12} sm={12} md={12} style={{ backgroundColor: `${nhlTeamColors[nhlTeam.team_id]}` }} className="nhl-bottom-banner">
                        {/* <img src={nhlTeam.team_logo_url} className="nhl-bottom-banner-logo"/> */}
                        <Typography variant="body1" component="h2" className="nhl-tea" style={{ fontWeight: 800 }}>
                            {nhlTeam.team_name}
                        </Typography>
                    </Grid>}

                    {!shortView && !miniView && playerData.draft_results && <Grid item xs={12} sm={12} md={12}
                        style={{ backgroundColor: `${nhlTeamColors[playerData.draft_results.team.team_id]}` }} className="player-nhl-draft">
                        <img src={playerData.draft_results.team.team_logo_url} className="nhl-bottom-banner-logo" />

                        <div className="draft-title">
                            <Typography variant="body1" component="h2" className="nhl-overall-pick" style={{ fontWeight: 800 }}>
                                {playerData.draft_results.draft_year} Draft: {pickAndRoundToOverall(playerData.draft_results.pick, playerData.draft_results.round, playerData.draft_results.draft_year)} Overall
                            </Typography>
                            <Typography variant="body1" component="h2" className="nhl-team-name" style={{ fontWeight: 200 }}>
                                {playerData.draft_results.team.team_name}
                            </Typography>
                        </div>
                    </Grid>}
                </div>
            </Link>
        </Card >
    );
};

export default PlayerCard;