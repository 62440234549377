import React from "react";
import { Typography, Card, CardContent } from '@mui/material';
import { format } from "date-fns";
import { Link } from 'react-router-dom';

// import "./PlayerRankMockCard.css"

function BlogPostCard({ blogPost }) {
    var date = new Date(blogPost.creation_date);
    var formattedDate = format(date, "MMM do, yyyy");

    return (
        <Card className="player-rank-mock-card">
            <CardContent className="player-rank-mock-card-content">
                <div className="player-rank-mock-card-description">
                <Typography variant="h5" component="div" className="player-rank-mock-header">
                    <Link to={"../blog-post/" + blogPost.blog_post_id}
                        style={{ color: "#1976d2", fontSize: "24px" }}>
                        {blogPost.title}
                    </Link>
                </Typography>
                <div className="player-rank-mock-subheader">
                <Typography color="textSecondary">
                    <Link reloadDocument={true} to={"../blog?author_id=" + blogPost.author.author_id} style={{ color: "#1976d2" }}> {blogPost.author.author_name}</Link>
                        
                    </Typography>
                    <div className="sub-heading-divider"></div>
                    <Typography color="textSecondary" sx={{ mb: 1 }}>
                        {formattedDate}
                    </Typography>
                </div>
                </div>
            </CardContent>
            <div className="player-rank-mock-card-divider"></div>
        </Card>

    );
};

export default BlogPostCard;