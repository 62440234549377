import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import parse from 'html-react-parser'
import axios from "axios";
import { format } from "date-fns";
import { Link } from 'react-router-dom';

import "./BlogPost.css"

const Content = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
}));

const BlogPost = () => {
    const [blogPost, setBlogPost] = useState(null);
    const [error, setError] = useState(null);
    const { blog_post_id } = useParams();


    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "/blog_posts/" + blog_post_id).then((response) => {
            setBlogPost(response.data);
            document.title = response.data.title + ' - Hockey Pipeline';
        })
            .catch((error) => {
                setError(error);
            });
    }, [blog_post_id]);

    return (
        <Container className="container top-container">
            <Grid container columnSpacing={4}>
                <Grid item xs={12} sm={12} md={12} >
                    {blogPost && <Content>
                        <Typography variant="h4" component="h1" gutterBottom>
                            {blogPost.title}
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            <div className="player-rank-mock-subheader">
                                <Link reloadDocument={true} to={"../blog?author_id=" + blogPost.author.author_id} style={{ color: "#1976d2" }}> {blogPost.author.author_name}</Link>
                                <div className="sub-heading-divider"></div>
                                {format(new Date(blogPost.creation_date), "MMM do, yyyy")}
                            </div>
                        </Typography>
                        <Typography variant="body1" paragraph className="blog-post-content">
                            <div>{parse(blogPost.blog_post_content)} </div>
                        </Typography>
                    </Content>}
                </Grid>
            </Grid>
        </Container>
    );
};

export default BlogPost;
